<template>
    <section id="title-box-component">
        <div class="container">
            <h3
                class="subtitle text"
                :class="topSubtitleClass"
                v-html="topSubtitle"
            ></h3>
            <h3 class="title text" :class="titleClass" v-html="title"></h3>
            <h3
                class="subtitle text"
                :class="bottomSubtitleClass"
                v-html="bottomSubtitle"
            ></h3>
        </div>
    </section>
</template>

<script>
export default {
    name: "TitleComp",
    props: {
        topSubtitle: String,
        topSubtitleClass: String,

        title: String,
        titleClass: String,

        bottomSubtitle: String,
        bottomSubtitleClass: String,
    },
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";

#title-box-component {
    text-align: center;
    color: var(--black);
    padding: 0.5%;
    margin-bottom: 1rem;
    @media (min-width: $breakpoint-mobile) {
        padding: 0.625rem;
        margin-bottom: 0;
    }

    > .container {
        max-width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .text {
            font-family: $font-family-inter;
            font-size: 0.938rem;
            color: var(--black);
            line-height: 1.3em;
            margin: 0;
            letter-spacing: -1px;
            @media (max-width: $breakpoint-mobile) {
            }
        }

        .title {
            font-weight: 700;
            letter-spacing: normal;
            @media (max-width: $breakpoint-mobile) {
                line-height: 1.563rem;
            }
        }

        .subtitle {
            font-weight: 400;
            letter-spacing: normal;
            @media (max-width: $breakpoint-mobile) {
            }
        }
    }
}
</style>
