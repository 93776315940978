<template>
  <div class="wistia-container">
    <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0; position:relative;">
      <div class="wistia_responsive_wrapper" style="height:100%; left:0; position:absolute; top:0; width:100%;">
        <div
          :class="`wistia_embed wistia_async_${videoId} seo=false videoFoam=true`"
          style="height:100%; position:relative; width:100%;"
          ref="wistiaEmbedContainer"
        >
          <div
            class="wistia_swatch"
            style="height:100%; left:0; opacity:0; overflow:hidden; position:absolute; top:0; transition:opacity 200ms; width:100%;"
          >
            <img
              :src="swatchImageUrl"
              style="filter:blur(5px); height:100%; object-fit:contain; width:100%;"
              alt=""
              aria-hidden="true"
              @load="handleLoad"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WistiaVideoEmbed",
  props: {
    videoId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showSwatch: false,
    };
  },
  computed: {
    swatchImageUrl() {
      return `https://fast.wistia.com/embed/medias/${this.videoId}/swatch`;
    }
  },
  created() {
    this.loadWistiaScript();
  },
  methods: {
    loadWistiaScript() {
      if (!document.querySelector('script[src="https://fast.wistia.net/assets/external/E-v1.js"]')) {
        const wistiaScript = document.createElement("script");
        wistiaScript.async = true;
        wistiaScript.src = "https://fast.wistia.net/assets/external/E-v1.js";
        document.body.appendChild(wistiaScript);
      }
    },
    handleLoad(event) {
      event.target.parentNode.style.opacity = 1;
    }
  }
};
</script>

<style scoped>
.wistia-container {
  max-width: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
</style>
