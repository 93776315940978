<template>
    <section id="benefits">
        <div class="container">
            <TitleComp
                topSubtitle="IF YOU CHOOSE TO WORK WITH US..."
                topSubtitleClass="text-topSubtitleClass"
                title="Here’s EVERYTHING You’ll Get..."
                titleClass="text-TitleClass"
            />

            <div class="row">
                <div class="col-md-6 padding-left">
                    <ImageComp
                        imageName="jovan-stage-2"
                        alt="Man in a stage"
                        :width="imgWidth"
                        :height="imgHeight"
                        imgClass="img-border-radius"
                    />
                </div>
                <div class="col-md-6 padding-right">
                    <ParagraphComp :texts="text" />
                </div>
            </div>
        </div>
        <PopButtonComp @click="$emit('click')" />
    </section>
</template>

<script>
import TitleComp from "./comps/TitleComp.vue";
import ImageComp from "./comps/ImageComp.vue";
import ParagraphComp from "./comps/ParagraphComp.vue";
import PopButtonComp from "./comps/PopButtonComp.vue";

export default {
    name: "BenefitsSection",
    components: {
        TitleComp,
        ImageComp,
        ParagraphComp,
        PopButtonComp,
    },
    computed: {
        imgWidth() {
            let width = "";
            if (this.windowWidth < 770) {
                width = "300";
            } else if (this.windowWidth < 992) {
                width = "360";
            } else if (this.windowWidth > 992) {
                width = "429";
            }
            return width;
        },
        imgHeight() {
            let width = "";
            if (this.windowWidth < 770) {
                width = "300";
            } else if (this.windowWidth < 992) {
                width = "570";
            } else if (this.windowWidth > 992) {
                width = "630";
            }
            return width;
        },
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            text: [
                {
                    html: "<strong>#1:</strong> Done-For-You Lead Generation",
                    custom: true,
                },
                {
                    html: "<strong>#2:</strong> Done-For-You Appointment Setting",
                    custom: true,
                },
                {
                    html: "<strong>#3:</strong> Done-For-You Reports/White Papers",
                    custom: true,
                },
                {
                    html: "<strong>#4:</strong> Personalized Sales Funnels",
                    custom: true,
                },
                {
                    html: "<strong>#5:</strong> Automated Nurture Campaigns",
                    custom: true,
                },
                {
                    html: "<strong>#6:</strong> Custom CRM Lead Management",
                    custom: true,
                },
                {
                    html: "<strong>#7:</strong> Step-By-Step Virtual Sales Training",
                    custom: true,
                },
                {
                    html: "<strong>#8:</strong> Gather & Showcase Client Reviews",
                    custom: true,
                },
                {
                    html: "<strong>#9:</strong> Advisor Mastermind Session/Week",
                    custom: true,
                },
                {
                    html: "<strong>#10:</strong> Top Annuities/Proprietary Products",
                    custom: true,
                },
                {
                    html: "<strong>#11:</strong> World Class Back Office",
                    custom: true,
                },
                {
                    html: "<strong>#12:</strong> 1:1 Virtual Sales Mentoring",
                    custom: true,
                },
                {
                    html: "<strong>#13:</strong> Live Advisor Training Events",
                    custom: true,
                },
                {
                    html: "<strong>#14:</strong> Incentive Trips",
                    custom: true,
                },
                {
                    text: "Ready to grow your annuity production and blow your clients away with massive value? Let us help you grow your practice with just an Internet connection and a cell phone…",
                    type: "bold",
                },
            ],
        };
    },
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";

#benefits {
    background: var(--bg-dark-blue);
    margin-top: -2px;
    padding: 40px 16px 45px;
    @media (min-width: $breakpoint-mobile) {
        padding: 116px 0;
        background: radial-gradient(
                38.17% 81.99% at 100% 50%,
                #033784 0%,
                rgba(5, 46, 95, 0) 100%
            ),
            radial-gradient(
                34.4% 73.89% at 0% 50%,
                #033784 0%,
                rgba(5, 46, 95, 0) 100%
            ),
            #032247;
    }
    @media (min-width: $breakpoint-tablet) {
        padding: 116px 0;
        margin-top: 0;
    }

    > .container {
        max-width: 100%;

        @media (min-width: $breakpoint-mobile) {
            max-width: 1170px;
        }
        @media (min-width: $breakpoint-tablet) {
            max-width: 80vw;
        }

        #title-box-component {
            margin-bottom: 2rem;
            @media (min-width: $breakpoint-mobile) {
            }

            .text-topSubtitleClass {
                font-size: 1rem !important;
                line-height: 1.3em;
                color: var(--white);
                margin-bottom: 1rem;
                @media (min-width: $breakpoint-mobile) {
                    font-size: 1.04169rem !important;
                }
                @media (min-width: $breakpoint-tablet) {
                    font-size: 1.25rem !important;
                }
            }
            .text-TitleClass {
                font-size: 1.75rem !important;
                line-height: 1.3em;
                padding: 0 16px;
                color: var(--white);
                font-family: $font-family-black;
                text-transform: uppercase;
                @media (min-width: $breakpoint-mobile) {
                    font-size: 2.34375rem !important;
                }
                @media (min-width: $breakpoint-tablet) {
                    padding: 0;
                    font-size: 2.813rem !important;
                }
            }
        }

        .row {
            margin-bottom: 0;

            @media (min-width: $breakpoint-mobile) {
                margin-bottom: 0.8rem;
            }

            .padding-left {
                padding-left: 0;

                @media (min-width: $breakpoint-tablet) {
                    padding-left: 10rem;
                }
            }
            .padding-right {
                padding-left: 0;

                @media (min-width: $breakpoint-tablet) {
                    padding-left: 2rem;
                }
            }

            #image-component {
                > .container {
                    padding: 0;
                    .image.img-border-radius {
                        border-radius: 10px;
                        height: 300px;
                        object-fit: cover;
                        object-position: top;
                        max-width:100%;
                        @media (min-width: $breakpoint-mobile) {
                            height: 100%;
                        }
                    }
                }
            }

            #paragraph {
                .p {
                    margin: 0;

                    p {
                        color: var(--white);
                        font-size: 1.125rem;
                        line-height: 2em;
                        margin: 0;
                        font-weight: 400;
                        font-family: $font-family-inter;

                        @media (min-width: $breakpoint-mobile) {
                            font-size: 1.042rem !important;
                        }
                        @media(min-width: $breakpoint-tablet) {
                            font-size: 1.25rem !important;
                        }
                    }
                }

                .p:first-child {
                    margin-top: 1rem;

                    @media (min-width: $breakpoint-mobile) {
                        margin-top: 0;
                    }
                }

                .p:last-child {
                    margin-top: 3.8rem;

                    p {
                        padding: 0;
                        font-size: 1.375rem !important;
                        line-height: 1.3em !important;
                        font-weight: 400 !important;
                        max-width: 100%;
                        font-weight: 700 !important;

                        @media (min-width: $breakpoint-mobile) {
                            font-size: 1.25rem !important;
                            max-width: 450px;
                        }
                    }
                }
            }
        }
    }
    #popup-button {
        .btn {
            padding: 1.03044rem 1.52844rem 1.07969rem 1.54563rem;
            .btn-book-link {
                .book-link {
                    color: var(--white);
                    text-decoration: underline;
                    font-size: 0.85rem;
                    padding: 0;
                    @media (min-width: $breakpoint-mobile) {
                        font-size: 1.375rem;
                    }
                }
            }
        }
    }
}
</style>
