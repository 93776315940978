<template>
    <section id="video-images-section-2">
        <div class="container">
            <VideoImages
                title="We Celebrate Our Advisors’ Wins"
                subtitle="We Are Big On Celebrating Our Advisors’ Wins And Accomplishments…Here Are Just A Few Of The Most Recent Wins Shared By Our Advisors:"
                isAdvisorWin
                color/>
        </div>
    </section>
</template>

<script>
import VideoImages from './comps/VideoImages.vue';
export default {
    name: 'VideoImagesSection2',
    components: {
        VideoImages,
    },
    data() {
        return {
            images: {
                Mike: {
                    left: [
                        {
                            name: "testimonials/Mike/T1",
                            alt: "Testimonial 1"
                        },
                        {
                            name: "testimonials/Mike/T3",
                            alt: "Testimonial 3"
                        },
                        {
                            name: "testimonials/Mike/T5",
                            alt: "Testimonial 5"
                        },
                        {
                            name: "testimonials/Mike/T7",
                            alt: "Testimonial 7"
                        },
                        {
                            name: "testimonials/Mike/T9",
                            alt: "Testimonial 9"
                        } 
                    ],
                    right: [
                    {
                            name: "testimonials/Mike/T2",
                            alt: "Testimonial 2"
                        },
                        {
                            name: "testimonials/Mike/T4",
                            alt: "Testimonial 4"
                        },
                        {
                            name: "testimonials/Mike/T6",
                            alt: "Testimonial 6"
                        },
                        {
                            name: "testimonials/Mike/T8",
                            alt: "Testimonial 8"
                        },
                        {
                            name: "testimonials/Mike/T10",
                            alt: "Testimonial 10"
                        }
                    ]
                },
                Sean: {
                    left: [
                        {
                            name: "testimonials/Mike/T1",
                            alt: "Testimonial 1"
                        },
                        {
                            name: "testimonials/Sean/T3",
                            alt: "Testimonial 3"
                        },
                        {
                            name: "testimonials/Sean/T2",
                            alt: "Testimonial 5"
                        },
                    ],
                    right: [
                    {
                            name: "testimonials/Sean/T2",
                            alt: "Testimonial 2"
                        },
                        {
                            name: "testimonials/Sean/T4",
                            alt: "Testimonial 4"
                        },
                        {
                            name: "testimonials/Mike/T1",
                            alt: "Testimonial 6"
                        },
                    ]
                },
                Barbara: {
                    left: [
                        {
                            name: "testimonials/Barbara/T1",
                            alt: "Testimonial 1"
                        },
                        {
                            name: "testimonials/Barbara/T3",
                            alt: "Testimonial 3"
                        },
                        {
                            name: "testimonials/Barbara/T5",
                            alt: "Testimonial 5"
                        },
                        {
                            name: "testimonials/Barbara/T7",
                            alt: "Testimonial 7"
                        },
                        {
                            name: "testimonials/Barbara/T9",
                            alt: "Testimonial 9"
                        },
                        {
                            name: "testimonials/Barbara/T11",
                            alt: "Testimonial 11"
                        },
                        {
                            name: "testimonials/Barbara/T13",
                            alt: "Testimonial 13"
                        }
                    ],
                    right: [
                    {
                            name: "testimonials/Barbara/T2",
                            alt: "Testimonial 2"
                        },
                        {
                            name: "testimonials/Barbara/T4",
                            alt: "Testimonial 4"
                        },
                        {
                            name: "testimonials/Barbara/T6",
                            alt: "Testimonial 6"
                        },
                        {
                            name: "testimonials/Barbara/T8",
                            alt: "Testimonial 8",
                        },
                        {
                            name: "testimonials/Barbara/T10",
                            alt: "Testimonial 10",
                        },
                        {
                            name: "testimonials/Barbara/T12",
                            alt: "Testimonial 12",
                        },
                    ],
                },
                Terry: {
                    left: [
                        {
                            name: "testimonials/Terry/T1",
                            alt: "Testimonial 1",
                        },
                        {
                            name: "testimonials/Terry/T3",
                            alt: "Testimonial 3",
                        },
                        {
                            name: "testimonials/Terry/T5",
                            alt: "Testimonial 5",
                        },
                        {
                            name: "testimonials/Terry/T7",
                            alt: "Testimonial 7",
                        },
                        {
                            name: "testimonials/Terry/T9",
                            alt: "Testimonial 9",
                        },
                        {
                            name: "testimonials/Terry/T11",
                            alt: "Testimonial 11",
                        },
                    ],
                    right: [
                        {
                            name: "testimonials/Terry/T2",
                            alt: "Testimonial 2",
                        },
                        {
                            name: "testimonials/Terry/T4",
                            alt: "Testimonial 4"
                        },
                        {
                            name: "testimonials/Terry/T6",
                            alt: "Testimonial 6"
                        },
                        {
                            name: "testimonials/Terry/T8",
                            alt: "Testimonial 8"
                        },
                        {
                            name: "testimonials/Terry/T10",
                            alt: "Testimonial 10"
                        }
                    ]
                },
                Advisors: {
                    left: [
                        {
                            name: "testimonials/AdvisorsClients/T1",
                            alt: "Testimonial 1"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T2",
                            alt: "Testimonial 2"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T3",
                            alt: "Testimonial 3"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T5",
                            alt: "Testimonial 5"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T6",
                            alt: "Testimonial 6"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T7",
                            alt: "Testimonial 7"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T8",
                            alt: "Testimonial 8"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T9",
                            alt: "Testimonial 9"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T15",
                            alt: "Testimonial 15"
                        },
                    ],
                    right: [
                    {
                            name: "testimonials/AdvisorsClients/T10",
                            alt: "Testimonial 10"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T11",
                            alt: "Testimonial 11"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T12",
                            alt: "Testimonial 12"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T13",
                            alt: "Testimonial 13"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T14",
                            alt: "Testimonial 14"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T16",
                            alt: "Testimonial 16"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T17",
                            alt: "Testimonial 17"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T18",
                            alt: "Testimonial 18"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T19",
                            alt: "Testimonial 19"
                        },
                    ]
                }
            }
        }
    }
    
}
</script>

<style lang="scss">
@import "@/assets/css/main.scss";
#video-images-section-2 {
    padding: 30px 10px;
    background: #032247;
    @media(min-width: $breakpoint-mobile) {
        padding: 7rem 1rem;
        background: radial-gradient(57.2% 42.04% at 50% 50%, #033C91 0%, #032247 100%);
    }
    @media (min-width: $breakpoint-tablet) {
        padding: 8rem 0 9.48rem;
        
    }
    .container {
    @media (min-width: $breakpoint-tablet) {
        max-width: 80vw;
    }
        .text-topSubtitleClass {
            margin-top: 1rem;
            font-size: 1rem;
            font-family: $font-family-inter;
            font-weight: 500;
            @media(min-width: $breakpoint-mobile) {
                font-size: 2.34375rem;
                line-height: normal;
            }
            @media (min-width: $breakpoint-tablet) {
                font-size: 1.25rem;
                line-height: 1.513rem;
            }
        }
        .text-TitleClass {
            font-size: 1.5rem;
            font-family: $font-family-black;
            text-transform: capitalize;
            color: var(--title-blue);
            @media(min-width: $breakpoint-mobile) {
                font-size: 1.5625rem;
                line-height: normal;
            }
            @media (min-width: $breakpoint-tablet) {
                font-size: 4.281rem;
                line-height: 5.403rem;
                padding: auto;
            }
        }
        .text-bottomSubtitleClass {
            font-size: 1.5rem;
            font-family: $font-family-bold;
            text-transform: capitalize;
            color: var(--title-blue);
            @media (min-width: $breakpoint-mobile) {
                font-size: 1.875rem;
                line-height: 2.321rem;
            }
        }
        .hr-yellow-1 {
            border: 1px solid var(--yellow);
            margin: 2% auto;
            width: 75%;
        }
        .hr-yellow {
            border: 1px solid var(--yellow);
            margin: 4% auto 2%;
        }
    }
}
</style>