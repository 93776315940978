<template>
    <section id="video-images">
        <div class="container">
            <h1 v-if="title" class="title" :class="{'font-ad-title' : isAdvisorWin}">{{ title }}</h1>
            <div class="vid-container">
                <WistiaVideo v-if="videoId" class="video" :videoId="videoId" />
            </div>
            <div v-if="isTerry" class="is-terry">
                <p class="subtext">
                    After taking a sabbatical from the business due to serious
                    health issues, he’s back and off to a strong start. Terry
                    and his son tried to make digital marketing work for their
                    practice by doing it themselves and got frustrated with all
                    the implementation, time, and money required.
                </p>
                <div class="img-text">
                    Here are some of the most recent wins Terry shared with us:
                </div>
                <div class="row">
                    <div class="col-12 col-md-6 img-left">
                        <img
                            src="@/assets/img/testimonials/Terry/terry-wins-1.png"
                            class="images img-left"
                        />
                    </div>
                    <div class="col-12 col-md-6 img-right">
                        <img
                            src="@/assets/img/testimonials/Terry/terry-wins-2.png"
                            class="images"
                        />
                    </div>
                </div>
            </div>
            <p v-if="subtitle" class="subtitle" :class="{'font-ad-text' : isAdvisorWin}">{{ subtitle }}</p>
            <div v-if="isAdvisorWin" class="advisor-wins">
                <img
                    class="advisor-win"
                    src="../../assets/img/testimonials/advisorswins.webp"
                    alt="Advisor Wins"
                />
            </div>
            <div v-if="imagesLeft && imagesRight" class="img-section row">
                <div class="col-12 col-md-6 img-left">
                    <ImageComp
                        v-for="img in imagesLeft"
                        :key="img"
                        :imageName="img.name"
                        :alt="img.alt"
                        class="images"
                        isShadow
                    />
                </div>
                <div class="col-12 col-md-6 img-right">
                    <ImageComp
                        v-for="img in imagesRight"
                        :key="img"
                        :imageName="img.name"
                        :alt="img.alt"
                        class="images"
                        isShadow
                    />
                </div>
            </div>
            <PopButtonComp @click="$emit('click')" :isWhite="color" />
        </div>
    </section>
</template>

<script>
import WistiaVideo from "@/components/comps/WistiaVideo.vue";
import ImageComp from "@/components/comps/ImageComp.vue";
import PopButtonComp from "./PopButtonComp.vue";
export default {
    name: "VideoImagesSection",
    components: {
        WistiaVideo,
        ImageComp,
        PopButtonComp,
    },
    props: {
        title: {
            type: String,
            default: "",
        },
        videoId: {
            type: String,
            default: "",
        },
        subtitle: {
            type: String,
            default: "",
        },
        imagesLeft: {
            type: Array,
        },
        imagesRight: {
            type: Array,
        },
        isTerry: {
            type: Boolean,
            default: false,
        },
        isAdvisorWin: {
            type: Boolean,
            default: false,
        },
        color: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";
.font-ad-title {
    color: var(--white) !important;
    font-size: 1.5rem !important;
    font-family: $font-family-black;
    font-weight: 700;
    line-height: 1.2rem;
    margin-top: 0;
    text-transform: uppercase;
    margin-bottom: 0 !important;
    @media(min-width: $breakpoint-mobile) {
        font-size: 2.0625rem !important;
        line-height: normal;
    }
    @media (min-width: $breakpoint-tablet) {
        font-size: 2.813rem !important;
        line-height: 3.549rem;
    }
}

.font-ad-text {
    color: var(--white) !important;
    letter-spacing: normal !important;
    @media (max-width: $breakpoint-mobile) {
       letter-spacing: -0.5px !important;
        font-weight: 400 !important;
        line-height: 1.7rem !important;
    }
}
#video-images {
    .container {
        padding: 0;
        @media (min-width: $breakpoint-tablet) {
            max-width: 996px;
        }
        .title {
            font-size: 1.7rem;
            color: var(--dark-blue);
            font-family: $font-family-black;
            font-weight: 700;
            line-height: 1.2em;
            margin-bottom: 1.4%;
            margin-top: 0;
            text-transform: uppercase;
            @media(min-width: $breakpoint-mobile) {
                font-size: 2.2rem;
                line-height: normal;
            }
            @media (min-width: $breakpoint-tablet) {
                font-size: 2.813rem;
                line-height: 3.549rem;
            }
        }
        .vid-container {
            .video {
                margin-top: 30px;
            }
        }
        .subtitle {
            font-size: 1rem;
            color: var(--dark-blue);
            font-family: $font-family-bold;
            margin: 32px auto;
            @media(min-width: $breakpoint-mobile) {
                font-size: 1.5625rem;
                line-height: normal;
            }
            @media (min-width: $breakpoint-tablet) {
                font-size: 1.875rem;
                line-height: 2.321rem;
                margin: 2.5rem 0;
            }
        }
        .img-section {
            padding-bottom: 0;
            @media(min-width: $breakpoint-tablet) {
                margin-bottom: 2.5rem;
            }
            .img-left,
            .img-right {
                .images {
                    margin-bottom: 1.5rem;
                    &:last-child {
                        @media(min-width: $breakpoint-mobile) {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .is-terry {
            .subtext {
                font-size: 0.9rem;
                color: var(--dark-blue);
                font-weight: 500;
                font-family: $font-family-inter;
                line-height: 1.3rem;
                letter-spacing: -0.5px;
                font-weight: 400;
                @media(min-width: $breakpoint-mobile) {
                    letter-spacing: 0.02606rem;
                    font-size: 1.04169rem;
                    line-height: normal;
                }
                @media (min-width: $breakpoint-tablet) {
                    font-size: 1.25rem;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    margin: 1rem auto 2.5rem;
                }
            }
            .img-text {
                font-size: 1.2rem;
                color: var(--title-blue);
                font-weight: 700;
                font-family: $font-family-bold;
                padding-bottom: 20px;
                @media(min-width: $breakpoint-mobile) {
                    font-size: 1.5625rem;
                    line-height: normal;
                    margin: 2rem auto;
                }
                @media (min-width: $breakpoint-tablet) {
                    font-size: 1.875rem;
                    line-height: 2.321rem;
                    margin-bottom: 2.5rem;
                }
            }
            .row {
                .img-left,
                .img-right {
                    .images {
                        width: 100%;
                        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
                    }
                    .img-left {
                        margin-bottom: 5%;
                        @media (min-width: $breakpoint-mobile) {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .advisor-wins {
            .advisor-win {
                box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
                max-width: 95%;
                border-radius: 10px;
                @media (min-width: $breakpoint-mobile) {
                    max-width: 75%;
                    margin-bottom: 32px;
                }
                @media (min-width: $breakpoint-tablet) {
                    margin-bottom: 0.625rem;
                }
            }
        }

        #popup-button {
            .book-link {
                color: var(--text-blue);
            }

            .book-link:hover {
                text-decoration: underline;
            }
        }
    }
}
</style>
