<template>
    <section id="about">
        <div class="container">
            <TitleComp
                topSubtitle="MEET THE FOUNDERS OF AIM..."
                topSubtitleClass="text-topSubtitleClass"
                :title="
                    isMobile
                        ? 'Jovan Will & <br> Fernando Godinez'
                        : 'Jovan Will & Fernando Godinez'
                "
                titleClass="text-TitleClass"
            />

            <div class="row one">
                <div class="col-md-7 video-column">
                    <WistiaVideo videoId="tia2o1y2ec" />
                </div>
                <div class="col-md-5 padding-right-3 one">
                    <ParagraphComp :texts="text.p1" />
                </div>
            </div>
        </div>

        <div
            :class="
                isMobile ? 'collage-container-mobile' : 'collage-container-web'
            "
        >
            <ImageComp
                v-if="!isMobile"
                imageName="collage-web"
                alt="logo googleads"
                width="100%"
                height="100%"
                imgClass="img-collage-web"
            />
            <ImageComp
                v-if="isMobile"
                imageName="collage-mobile"
                alt="logo googleads"
                width="100%"
                height="100%"
                imgClass="img-collage-mobile"
            />
        </div>

        <div class="container">
            <TitleComp class="mb-4"
                topSubtitle="HOW WE CONNECT QUALITY ADVISORS WITH QUALIFIED PROSPECTS"
                topSubtitleClass="text-topSubtitleClass"
                :title="
                    isMobile
                        ? 'Matching Annuity Prospects With Advisors'
                        : 'Matching Annuity Prospects With Advisors'
                "
                titleClass="text-TitleClass"
            />

            <div class="row">
                <div class="col-md-6 img-section">
                    <ImageComp
                        imageName="about-rating"
                        alt="We are rated Excellent"
                        imgClass="img-rating"
                        :width="ratingWidth"
                        :height="ratingHeight"
                    />
                </div>
                <div class="col-md-6 padding-right-3 two">
                    <ParagraphComp :texts="text.p2" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TitleComp from "./comps/TitleComp.vue";
import ParagraphComp from "./comps/ParagraphComp.vue";
import ImageComp from "./comps/ImageComp.vue";
import WistiaVideo from "./comps/WistiaVideo.vue";

export default {
    name: "AboutSection",
    components: {
        TitleComp,
        ParagraphComp,
        ImageComp,
        WistiaVideo,
    },
    computed: {
        isMobile() {
            return this.windowWidth <= 770;
        },
        isTablet() {
            return this.windowWidth <= 992;
        },
        ratingWidth() {
            let width = "";
            if (this.windowWidth < 770) {
                width = "300";
            } else if (this.windowWidth < 992) {
                width = "350";
            } else if (this.windowWidth > 992) {
                width = "510";
            }
            return width;
        },
        ratingHeight() {
            let width = "";
            if (this.windowWidth < 770) {
                width = "300";
            } else if (this.windowWidth < 992) {
                width = "300";
            } else if (this.windowWidth > 992) {
                width = "415";
            }
            return width;
        },
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            text: {
                p1: [
                    {
                        text: "In 2009, Jovan and Fernando launched the industry’s first online annuity sales funnel.",
                    },
                    {
                        text: "Jovan and Fernando have generated over 500,000 targeted annuity leads online and managed the digital marketing campaigns for multi-billion dollar FMOs, RIAs, and Inc 500 companies.",
                    },
                    {
                        text: "Their background and expertise is in scaling annuity advertising through automation, digital marketing, and media buying.",
                    },
                    {
                        text: "AIM’s co-founders have actively managed 8 figures of advertising spend in digital marketing.",
                    },
                    {
                        text: "Jovan and Fernando are really committed to helping advisors build a modern, location-independent practice that supports their ideal lifestyle.",
                    },
                ],
                p2: [
                    {
                        text: "We own a nationally recognized website that educates consumers about annuities and retirement planning.",
                    },
                    {
                        text: "Launched in 2012, as the original annuity “investigator”, we have published annuity reviews, and 180,000 Baby Boomers each year access free retirement tips and trusted annuity advice.",
                    },
                    {
                        text: "Through our website, consumers get connected with our licensed “Annuity Investigators” to research, compare, and purchase the best annuities.",
                    },
                    {
                        html: "<strong> We have earned an EXCELLENT review rating after gathering 370+ verified client reviews. </strong>",
                        custom: true,
                    },
                    {
                        text: "Our vetted and trained advisors provide consumers with honest annuity guidance and personalized annuity recommendations.",
                    },
                ],
            },
        };
    },
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";

#about {
    background: var(--bg-dark-blue);
    padding: 20px 16px;

    @media (min-width: $breakpoint-mobile) {
        background: radial-gradient(
            77.39% 30.82% at 50% 50%,
            #023a8c 3.5%,
            #021e3d 80%
        );
        padding: 70px 1rem 40px;
    }
    @media (min-width: $breakpoint-tablet) {
        padding: 6.9rem 0 7rem;
    }
    > .container {
        max-width: 100%;

        @media (min-width: $breakpoint-mobile) {
            max-width: 1170px;
        }
        @media (min-width: $breakpoint-desktop) {
            max-width: 80vw;
        }
        .row {
            .img-section {
                padding: 0;
                @media (min-width: $breakpoint-mobile) {
                    padding: 0 1rem;
                }
                #image-component {
                    .container {
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
        .text-topSubtitleClass {
            margin-top: 0.75rem;
            font-size: 1rem !important;
            text-align: left;
            font-family: $font-family-inter;
            letter-spacing: 0.083px;

            @media (min-width: $breakpoint-mobile) {
                text-align: center;
                font-size: 1.042rem !important;
            }
            @media (min-width: $breakpoint-tablet) {
                font-size: 1.25rem !important;
            }
        }
        #title-box-component {
            .container {
                align-items: flex-start;

                @media (min-width: $breakpoint-mobile) {
                    align-items: center;
                }
            }
        }
        #title-box-component:first-of-type .text-topSubtitleClass {
            color: var(--yellow);
            margin-bottom: 12px;
        }
        #title-box-component:nth-of-type(2) .text-topSubtitleClass {
            color: var(--white);
            margin-top: 2rem;
            font-family: $font-family-inter;
            @media (min-width: $breakpoint-mobile) {
                margin-top: 3rem;
            }
            @media (min-width: $breakpoint-tablet) {
                margin-top: 6.5rem;
            }
        }

        #title-box-component:nth-of-type(2) .text-TitleClass {
            margin: 12px 0 0;
            @media (min-width: $breakpoint-mobile) {
                margin: 12px 0 3rem 0;
            }
            @media (min-width: $breakpoint-tablet) {
                margin: 12px 0 4rem 0;
            }
        }

        .text-TitleClass {
            color: var(--white);
            font-size: 2rem !important;
            line-height: 1.3em;
            text-align: left;
            font-family: $font-family-black;

            @media (min-width: $breakpoint-mobile) {
                text-align: center;
                font-size: 3.4rem !important;
            }
            @media (min-width: $breakpoint-tablet) {
                font-size: 4.28125rem !important;
            }
        }

        .row.one {
            margin-top: 0;
            @media (min-width: $breakpoint-mobile) {
                margin-top: 3.75rem;
            }
        }

        .padding-right-3.one {
            margin-top: 1rem;

            @media (min-width: $breakpoint-mobile) {
                margin-top: 0;
                padding: 0 1rem;
            }
            @media (min-width: $breakpoint-tablet) {
                margin-top: 0;
                padding: 0 0.5rem 0 1.5rem;
            }

            #paragraph {
                .p {
                    line-height: normal;
                }
                .p:first-child {
                    margin-top: 0;
                }
            }
        }
        .padding-right-3.two {
            margin-top: 1rem;

            @media (min-width: $breakpoint-mobile) {
                margin-top: 0;
                padding: 0 1rem;
            }
            @media (min-width: $breakpoint-tablet) {
                margin-top: 0;
                padding: 0 0.5rem 0 1rem;
            }

            #paragraph {
                .p {
                    line-height: normal;
                }
                .p:first-child {
                    margin-top: 0;
                }
            }
        }

        .row {
            .video-column {
                margin-top: 0;
                @media (min-width: $breakpoint-mobile) {
                    display: grid;
                    align-items: center;
                    padding: 0 2%;
                }
                @media (min-width: $breakpoint-tablet) {
                    padding: 0 0.7rem 1rem;
                }
            }

            #image-component {
                > .container {
                    padding: 0;
                }
            }
        }

        #paragraph {
            .p {
                color: var(--white);
                font-size: 1rem;
                font-weight: 300;
                line-height: normal;
                letter-spacing: 0.02rem;

                @media (min-width: $breakpoint-mobile) {
                    font-size: 1.03rem;
                    letter-spacing: 0.02606rem;
                }
                @media (min-width: $breakpoint-tablet) {
                    font-size: 1.25rem;
                    letter-spacing: 0.03125rem;
                }
            }
        }

        .inc-logo {
            max-width: 57px;
            max-height: 34px;
        }
    }

    .collage-container-web {
        margin: 2rem 0;

        @media (min-width: $breakpoint-desktop) {
            margin: 5rem 0;
        }

        #image-component {
            .container {
                max-width: 100%;
            }
        }
    }
    .collage-container-mobile {
        margin: 1.5rem 0;
    }
}
</style>
