<template>
    <section id="image-component">
        <div class="container">
            <img :src="getImageSource()" :alt="alt" :width="width" :height="height" class="image" :class="[imgClass, {'img-shadow' : isShadow}]" >
        </div>
    </section>
</template>

<script>
export default {
    name: "ImageComp",
    props: {
        imageName: String,
        alt: String,
        imgClass: String,
        width: {
            type: [String, Number],
            default: "300",
        },
        height: {
            type: [String, Number],
            default: "200",
        },
        isShadow: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getImageSource() {
            const supportsWebP = (() => {
            try {
                return document.createElement('canvas').toDataURL('image/webp').startsWith('data:image/webp');
            } catch (err) {
                return false;
            }
            })();
    
            if (supportsWebP) {
                return require(`@/assets/img/${this.imageName}.webp`);
            } else {
                return require(`@/assets/img/${this.imageName}.png`);
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/main.scss';
#image-component {
    .container {
        padding: 0;
        @media (min-width: $breakpoint-mobile) {
        }
    }
}
.img-shadow {
    box-shadow: 0 1px 5px rgba(0,0,0,0.2) !important;
    width: 100%;
    height: auto;
}
.image {
    vertical-align: middle;
    @media (max-width: $breakpoint-mobile) {
        height: 100%;
        width: 100%;
    }
}
</style>