<template>
    <HeaderSection />

    <MainVideoSection />

    <BulletColumnSection/>

    <AboutSection />

    <VideoImagesSection />

    <VideoImagesSection2 />

    <VideoImagesSection3 />

    <BookingSection />
    
    <BenefitsSection />

    <LetterSection />

    <FooterSection />
</template>

<script>
import HeaderSection from "@/components/Header.vue";
import MainVideoSection from "@/components/MainVideoSection.vue";
import AboutSection from "@/components/About.vue";
import BookingSection from "@/components/Booking.vue";
import BenefitsSection from "@/components/Benefits.vue";
import LetterSection from "@/components/Letter.vue";
import FooterSection from "@/components/Footer.vue";
import BulletColumnSection from "@/components/BulletColumnSection.vue";
import VideoImagesSection from "@/components/VideoImagesSection.vue";
import VideoImagesSection2 from "@/components/VideoImagesSection2.vue";
import VideoImagesSection3 from "@/components/VideoImagesSection3.vue";
export default {
    name: "CallPage",
    mounted() {
        this.trackQuizCompletion('Arrived')
    },
    components: {
        HeaderSection,
        MainVideoSection,
        AboutSection,
        BookingSection,
        BenefitsSection,
        LetterSection,
        FooterSection,
        BulletColumnSection,
        VideoImagesSection,
        VideoImagesSection2,
        VideoImagesSection3
    },
    methods: {
        sendEventData(eventData) {
            fetch('/mixpnl.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(eventData)
            })
            .then(response => {
            // Handle the response if needed
            console.log(response);
            })
            .catch(error => {
            // Handle any errors that occur during the fetch request
            console.error(error);
            });
        },
        trackQuizCompletion(quizCompletion) {
            // Extract UTM parameters from the URL
            function getUrlParams(url) {
                var params = {};
                var parser = document.createElement('a');
                parser.href = url;
                var query = parser.search.substring(1);
                var vars = query.split('&');
                for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=');
                params[pair[0]] = decodeURIComponent(pair[1]);
                }
                return params;
            }

            const urlParams = getUrlParams(window.location.href);

            // Track the quiz completion using Mixpanel
            const eventData = {
                event: "AIM Reruiting Organic / Landing Page",
                properties: {
                quizStatus: quizCompletion,
                time: Date.now() / 1000, 
                distinct_id: this.dist_id, 
                $browser: navigator.userAgent, 
                $current_url: window.location.href,
                $initial_referrer: document.referrer.split('/')[2], 
                $os: navigator.platform,
                $referrer: document.referrer,
                $screen_height: window.screen.height,
                $screen_width: window.screen.width,
                }
            }

            // If UTM parameters exist in the URL, add them to the eventData
            if (urlParams.utm_source) {
                eventData.properties.utm_source = urlParams.utm_source;
            }
            if (urlParams.utm_medium) {
                eventData.properties.utm_medium = urlParams.utm_medium;
            }
            if (urlParams.utm_campaign) {
                eventData.properties.utm_campaign = urlParams.utm_campaign;
            }
            if (urlParams.utm_term) {
                eventData.properties.utm_term = urlParams.utm_term;
            }
            if (urlParams.utm_content) {
                eventData.properties.utm_content = urlParams.utm_content;
            }

            // Send the event data to Mixpanel
            this.sendEventData(eventData);
        },
    }
};
</script>

<style lang="scss" scoped>

</style>
