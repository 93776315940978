<template>
    <section id="header">
        <div class="container">
            <ImageComp
                imageName="AIM-AE-Logo"
                alt="AIM Powered by Advisors Excell"
                :width="logoWidth"
                :height="logoHeight"
            />

            <p class="header-text">
                Need help? Call:
                <a href="tel:8889792701">(888) 979-2701</a> (or) email:
                <a href="mailto:info@advisorinternetmarketing.com"
                    >info@advisorinternetmarketing.com</a
                >
            </p>
        </div>
    </section>
</template>
<script>
import ImageComp from "./comps/ImageComp.vue";

export default {
    name: "HeaderSection",
    components: {
        ImageComp,
    },
    data() {
        return {
            windowWidth: window.innerWidth,
        };
    },
    computed: {
        logoWidth() {
            let width = "";
            if (this.windowWidth < 770) {
                width = "180";
            } else if (this.windowWidth < 992) {
                width = "189";
            } else if (this.windowWidth > 992) {
                width = "226";
            }
            return width;
        },
        logoHeight() {
            let width = "";
            if (this.windowWidth < 770) {
                width = "32";
            } else if (this.windowWidth < 992) {
                width = "26.7";
            } else if (this.windowWidth > 992) {
                width = "32";
            }
            return width;
        },
    },
};
</script>
<style lang="scss">
@import "@/assets/css/main.scss";

#header {
    background-color: var(--dark-blue);
    height: 60px;
    @media (min-width: $breakpoint-mobile) {
        height: 3.75rem;
        background-color: var(--dark-black);
    }
    @media (min-width: $breakpoint-tablet) {
        height: 4.5rem;
        background-color: var(--dark-black);
    }

    > .container {
        height: 100%;
        padding: 26.5px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (min-width: $breakpoint-mobile) {
            justify-content: space-between;
            width: 90%;
        }
        @media (min-width: $breakpoint-tablet) {
            max-width: 1170px;
            width: 100%;
        }
        @media (min-width: $breakpoint-desktop) {
            max-width: 90vw;
            width: 100%;
        }

        #image-component {
            > .container {
                padding: 0;

                img {
                    max-width: 226px;
                    max-height: 32px;
                }
            }
        }

        .header-text {
            color: var(--white);
            margin: 0.5rem 0 0;
            font-family: $font-family-inter;
            font-weight: 200;
            display: none;
            margin: 0;
            font-size: 0.83331rem;
            margin-top: 0;
            @media (min-width: $breakpoint-mobile) {
                font-size: 0.83331rem;
                display: block;
                line-height: normal;
            }
            @media (min-width: $breakpoint-tablet) {
                display: block;
                font-size: 1rem;
                line-height: normal;
            }

            a {
                color: var(--white) !important;
                text-underline-position: under;
                text-decoration-thickness: from-font;
            }
        }
    }
}
</style>
