import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import Vuex from 'vuex';
import storeData from './store';

import './assets/css/bootstrap-grid.min.css';
import './assets/css/main.scss';

const store = new Vuex.Store(storeData);

createApp(App).use(router).use(store).mount('#app')
