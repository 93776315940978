<template>
    <section id="letter">
        <div class="container">
            <TitleComp
                title="Thanks For Taking The Time To Read This! We Look Forward To Speaking With You Soon…"
                titleClass="text-titleClass"
            />
            <div class="quote">
                <p class="q"><strong>- The “Virtual” Advisor Guys,</strong></p>
                <p class="author">Jovan Will & Fernando Godinez</p>
            </div>
            <ParagraphComp :texts="text" />

            <PopButtonComp
                linkText="<strong>Click here to book your risk-free, no obligation call with our team today.</strong>"
                justLink
                @click="$emit('click')"
            />

            <ParagraphComp :texts="text2" />
        </div>
    </section>
</template>

<script>
import { mapActions } from "vuex";

import TitleComp from "./comps/TitleComp.vue";
import ParagraphComp from "./comps/ParagraphComp.vue";
import PopButtonComp from "./comps/PopButtonComp.vue";

export default {
    name: "LetterSection",
    components: {
        TitleComp,
        ParagraphComp,
        PopButtonComp,
    },
    data() {
        return {
            text: [
                {
                    html: "<strong>P.S.</strong> In case you're one of those people (like me) who just skips to the end, here's the deal:",
                    custom: true,
                },
                {
                    text: "Our team has spent over the last decade mastering the art and science of virtual client acquisition. Much of what we’ve learned is from over a decade in the trenches, trial and error, and investing millions of dollars in advertising annuities online.",
                },
                {
                    text: "We've helped advisors all across the country transition from relying on old-school traditional methods to building a thriving and wildly profitable location-independent practice.",
                },
                {
                    text: "After being pursued by multiple FMOs/IMOs to work with us, we chose to partner with the industry’s largest and most successful FMO, Advisors Excel, to help us deliver world-class services to our advisors.",
                },
                {
                    text: "During our Discovery Call, we’ll assess your business and lifestyle goals and determine how we can best help you grow your practice… whether that’s focusing on digital marketing, virtual sales, or access to the best annuity products.",
                },
                {
                    text: "At this point, you have two options:",
                },
                {
                    html: "<strong>Option 1:</strong> Do nothing. Leave this page and continue to rely on the traditional methods of growing your practice being pushed by all the marketing groups and FMOs.",
                    custom: true,
                },
                {
                    html: "<strong>Option 2:</strong> Take a chance. Book a call with our team. See if this is a good fit for you.",
                    custom: true,
                },
                {
                    text: "*Note: This is *NOT* a sales call. The results we provide are based on the level of commitment of the advisors we work with, so we have no incentive to recommend you work with us unless we are confident that we are a good fit to work together.",
                },
                {
                    text: "If it's not a good match, we'll tell you, & recommend resources for you (whether that's working with a different marketing group or plugging into free resources we provide).",
                },
                {
                    text: "So this call is 100% risk-free.",
                },
                {
                    text: "You either don't work with us & we go separate ways... or you do work with us and we help you build a wildly profitable location-independent practice.",
                },
                {
                    text: "Either way, you win.",
                },
                {
                    text: "That being said:",
                },
                {
                    text: "If you are an experienced independent advisor who wants to grow their practice with just an Internet connection and a cell phone...",
                },
            ],
            text2: [
                {
                    text: "And let's see if we can help you build your dream practice.",
                },
                {
                    text: "Chat soon! ✌🏼",
                },
            ],
        };
    },
    methods: {
        ...mapActions(["toggleModal"]),
    },
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";

#letter {
    background-color: var(--white);
    padding: 20px 16px;

    @media (min-width: $breakpoint-mobile) {
        padding: 100px 0 116px;
    }

    > .container {
        max-width: 100%;
        padding: 0;
        @media (min-width: $breakpoint-mobile) {
            max-width: 90%;
        }
        @media (min-width: $breakpoint-tablet) {
            max-width: 996px;
        }
        @media (min-width: $breakpoint-tablet) {
            max-width: 80vw;
        }
        .quote {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .q,
            .author {
                font-size: 1.125rem !important;
                line-height: normal;
                font-weight: 400;

                @media (min-width: $breakpoint-mobile) {
                    font-size: 1.25rem !important;
                }
            }
            .q {
                margin-bottom: 0;
            }
            .author {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        #title-box-component {
            padding: 0;

            .text-titleClass {
                color: #040F21;
                font-size: 1.8rem !important;
                margin-top: 1rem;
                line-height: 1.3em;
                font-family: $font-family-black;
                text-transform: uppercase;
                text-align: left;
                padding-right: 0;

                @media (min-width: $breakpoint-mobile) {
                    margin-top: 0;
                    font-size: 2.813rem !important;
                    padding-right: 3rem;
                }
            }
        }

        #paragraph {
            > .container {
                max-width: 100%;
            }

            .p {
                color: var(--black);
                font-size: 1.125rem !important;
                line-height: normal;
                font-weight: 400;

                @media (min-width: $breakpoint-mobile) {
                    font-size: 1.25rem !important;
                }
            }

            .p:first-child p {
                margin-bottom: 0;
            }
            .p:nth-child(2) p {
                margin-top: 0;
            }
        }

        #popup-button > .container {
            margin: 0;

            .btn-book-link .blue-book-link {
                display: block;
                text-align: left;
                padding: 0;
                font-size: 1.125rem;
                line-height: 1.3em;

                @media (min-width: $breakpoint-mobile) {
                    font-size: 1.5rem;
                }
            }
        }
    }
}
</style>
