<template>
    <section id="paragraph">
        <div class="container">
            <h1 v-if="title" class="title" :class="{'title-padding' : needsPadding}">{{ title }}</h1>
            <div class="p" v-for="text in texts" :key="text">
                <p v-if="text.custom && text.html" :class="{'center' : text.align === 'center', 'm-0' : m0}" v-html="text.html"></p>
                <p :class="{'bold' : text.type === 'bold', 'underlined' : text.type === 'underlined', 'center' : text.align === 'center', 'text-sm' : is16px}" v-else>{{ text.text }}</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ParagraphComp',
    props: {
        title: {
            type: String,
            default: ''
        },
        texts : {
            type: Array
        },
        custom: {
            type: Boolean,
            default: false
        },
        needsPadding: {
            type: Boolean,
            default: false
        },
        is16px: {
            type: Boolean,
            default: false
        },
        m0: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/main.scss";

#paragraph {
    color: var(--black);
    
    > .container {
        padding: 0;

        > .title {
            font-size: 1.375rem;
            font-family: $font-family !important;
            line-height: 1.3em;
            font-weight: 400;
            @media (min-width: $breakpoint-mobile) {

            }
            @media (min-width: $breakpoint-tablet) {
                font-size: 1.875rem;
            }
        }
        > .title-padding {
            padding: 0 13%;
        }
        > .text-sm {
            font-size: 1rem !important;
        }
        > .m0 {
            margin: 0 !important;
        }
        
        > .p {
            font-size: 1rem;
            text-align: left;
            font-family: $font-family-inter;
            font-weight: 400;
            line-height: 1.5rem;
            margin: 3% auto;
            color: var(--text-blue);
            @media (min-width: $breakpoint-mobile) {
                font-size: 1.04169rem;
            }
            @media (min-width: $breakpoint-tablet) {
                font-size: 1.25rem;
                line-height: 1.513rem;
                letter-spacing: 0.1px;
            }
            & .bold {
                font-weight: bold !important;
            }
            & .underlined {
                text-decoration: underline !important;
            }
            & .center {
                text-align: center !important;
            }
            & :first-child {
                margin-top: 0 !important;
            }

        }
    }
}
</style>