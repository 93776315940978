<template>
    <section id="dot-bullet">
        <div class="container">
            <div v-if="title" class="title-section">
                <h1 class="title">{{ title }}</h1>
                <hr class="divider" />
            </div>
            <div class="bullet-section">
                <div
                    v-for="bullet in bullets"
                    :key="bullet"
                    class="bullet-cont"
                >
                    <h1 class="bullet-title">
                        <SvgIcon
                            v-if="bullet.icon"
                            :name="bullet.icon"
                            width="20"
                            height="20"
                            fill-color="#000"
                            class="icon"
                        />
                        {{ bullet.title }}
                    </h1>
                    <p class="text">
                        {{ bullet.text }}
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import SvgIcon from "./SvgIcon.vue";
export default {
    name: "DotBullet",
    components: {
        SvgIcon,
    },
    props: {
        title: {
            type: String,
            default: "",
        },
        bullets: {
            type: Array,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/main.scss";
#dot-bullet {
    &:first-child {
        margin-top: 2rem;
        @media (min-width: $breakpoint-mobile) {
            margin-top: 4rem;
        }
        @media (min-width: $breakpoint-tablet) {
            margin-top: 5rem;
        }
    }
    &:nth-child(2) {
        margin-top: 3rem;
        @media (min-width: $breakpoint-mobile) {
        }
        @media (min-width: $breakpoint-tablet) {
            margin-top: 2rem;
        }
    }
    .container {
        padding: 0;
        .title-section {
            text-align: left;
            width: fit-content;
            @media (min-width: $breakpoint-mobile) {
                margin-bottom: 2.2rem;
            }
            @media (min-width: $breakpoint-tablet) {
                margin-bottom: 2.5rem;
            }
            .title {
                margin: 1% auto;
                font-size: 1.75rem;
                line-height: 2.45rem;
                margin-bottom: 1%;
                text-transform: uppercase;
                font-family: $font-family-black;
                color: var(--title-black);
                @media (min-width: $breakpoint-mobile) {
                    font-size: 2.1rem;
                    line-height: normal;
                }
                @media (min-width: $breakpoint-tablet) {
                    font-size: 2.813rem;
                    line-height: 3.549rem;
                }
            }
            .divider {
                background-color: var(--yellow);
                border: none;
                height: 10px;
                width: auto;
            }
        }
        .bullet-section {
            .bullet-cont {
                width: 100%;
                margin-bottom: 1.5rem;
                @media (min-width: $breakpoint-mobile) {
                    margin-bottom: 3rem;
                }
                @media (min-width: $breakpoint-tablet) {
                    margin-bottom: 2rem;
                }
                .bullet-title {
                    color: var(--text-black);
                    font-family: $font-family-bold;
                    font-size: 1rem;
                    text-align: left;
                    margin: 0.5rem auto;
                    display: flex;
                    align-items: center;
                    @media (min-width: $breakpoint-mobile) {
                        font-size: 1.25rem;
                        margin: 1rem auto;
                    }
                    @media (min-width: $breakpoint-tablet) {
                        font-size: 1.7rem;
                    }
                    .icon {
                        margin-right: 4.5px;
                        @media (min-width: $breakpoint-mobile) {
                            margin-right: 6.667px;
                        }
                        @media (min-width: $breakpoint-tablet) {
                            margin-right: 8px;
                        }
                    }
                }
                .text {
                    font-family: $font-family-inter;
                    font-size: 1.125rem;
                    color: var(--text-black);
                    text-align: left;
                    margin: 1% auto;
                    font-weight: 400;
                    @media (min-width: $breakpoint-mobile) {
                        font-size: 1.03rem;
                    }
                    @media (min-width: $breakpoint-tablet) {
                        letter-spacing: 0.5px;
                        font-size: 1.25rem;
                        line-height: normal;
                    }
                }
            }
        }
    }
}
</style>
