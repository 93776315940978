<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: 'App',
  mounted() {
    // Load Google Tag Manager script
    const gtmScript = document.createElement('script');
    gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-WR76W6');`;
    document.head.appendChild(gtmScript);

    // Google Tag Manager (noscript)
    const gtmNoScript = document.createElement('noscript');
    gtmNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WR76W6"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(gtmNoScript);
  },

  created() {
    // Load Calendly script
    const calendlyScript = document.createElement('script');
    calendlyScript.src = 'https://assets.calendly.com/assets/external/widget.js';
    calendlyScript.async = true;
    document.head.appendChild(calendlyScript);

    //Get and save UTMs
    this.getUTMs();
    this.setFormData(this.formData);
  },
  data() {
    return {
      formData: {
                utm_source: "",
                utm_medium: "",
                utm_campaign: "",
                utm_term: "",
                utm_content: "",
                gclid: "",
            },
    }
  },
  methods: {
    ...mapActions(["setFormData"]),
        getUTMs() {
            const urlParams = new URLSearchParams(window.location.search);
            const utmFields = [
                "utm_source",
                "utm_medium",
                "utm_campaign",
                "utm_term",
                "utm_content",
            ];
            utmFields.forEach((field) => {
                if (urlParams.has(field)) {
                    this.formData[field] = urlParams.get(field);
                }
            });

            if (urlParams.has("gclid")) {
                this.formData["gclid"] = urlParams.get("gclid");
            }
        },
  }
}
</script>

<style>
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
  overflow-x: hidden;
}
</style>
