<template>
    <section id="bullet-column-section">
        <div class="container">
            <div class="logo-section">
                <h1 class="title-logo">
                    Grow Your Practice With Just An Internet Connection And A
                    Cell Phone
                </h1>
                <p class="subtitle">
                    (No Office, No Dinner Seminars, No Staff, No Problem!)
                    <br v-if="!isMobile" />
                    Get access to the best annuity contracts from top-rated
                    insurance companies, and keep 100% of
                    your commission on business.
                </p>
            </div>
            <div class="img-section" v-if="!isMobile">
                <ImageComp
                    imageName="logo-athene"
                    :width="atheneWidth"
                    :height="atheneHeight"
                />
                <ImageComp
                    imageName="logo-symetra"
                    :width="symetraWidth"
                    :height="symetraHeight"
                />
                <ImageComp
                    imageName="delaware-logo"
                    :width="delawareWidth"
                    :height="delawareHeight"
                />
                <ImageComp
                    imageName="protective-logo"
                    :width="protectWidth"
                    :height="protectHeight"
                />
            </div>
            <div v-else class="img-section">
                <ImageComp imageName="logos-inline" />
            </div>
            <div class="row">
                <div class="col-12 col-md-6 left-side">
                    <div class="card-section">
                        <h1 class="title">
                            What If You Could Predictably Close Annuity Business
                            With More Freedom And Ease...?
                        </h1>
                        <ParagraphComp class="texts" :texts="texts" />
                        <u class="link"
                            ><a class="link-anchor" @click="goToForm"
                                >Click here to book a complimentary 1:1
                                Discovery Call with our team today.</a
                            ></u
                        >
                        <p class="subtext">Chat soon!</p>
                    </div>
                </div>
                <div class="btn-section" v-if="isMobile">
                    <PopButtonComp
                        linkText="Tap here to book your discovery phone call"
                        @click="$emit('click')"
                    />
                </div>
                <div class="col-12 col-md-6 right-side">
                    <div class="bullet-section">
                        <DotBullet
                            title="What You’ll Get:"
                            :bullets="bullets1"
                        />
                        <DotBullet
                            title="Additional Bonuses:"
                            :bullets="bullets2"
                        />
                    </div>
                </div>
            </div>
            <div class="btn-section">
                <PopButtonComp
                    linkText="Tap here to book your discovery phone call"
                    @click="$emit('click')"
                />
            </div>
        </div>
    </section>
</template>

<script>
import { mapState } from "vuex";
import ParagraphComp from "./comps/ParagraphComp.vue";
import DotBullet from "./comps/DotBullet.vue";
import PopButtonComp from "./comps/PopButtonComp.vue";
import ImageComp from "./comps/ImageComp.vue";

export default {
    name: "BulletColumnSection",
    components: {
        ParagraphComp,
        DotBullet,
        PopButtonComp,
        ImageComp,
    },
    computed: {
        ...mapState(["formData"]),
        isMobile() {
            return this.windowWidth <= 770;
        },
        atheneWidth() {
            let width = "";
            if (this.windowWidth < 770) {
                width = 125;
            } else if (this.windowWidth < 992) {
                width = 179.167;
            } else if (this.windowWidth > 992) {
                width = 215;
            }
            return width;
        },
        atheneHeight() {
            let width = "";
            if (this.windowWidth < 770) {
                width = 25;
            } else if (this.windowWidth < 992) {
                width = 30.163;
            } else if (this.windowWidth > 992) {
                width = 36.195;
            }
            return width;
        },
        symetraWidth() {
            let width = "";
            if (this.windowWidth < 770) {
                width = 100;
            } else if (this.windowWidth < 992) {
                width = 110.833;
            } else if (this.windowWidth > 992) {
                width = 133;
            }
            return width;
        },
        symetraHeight() {
            let width = "";
            if (this.windowWidth < 770) {
                width = "45";
            } else if (this.windowWidth < 992) {
                width = "47.182";
            } else if (this.windowWidth > 992) {
                width = "56.618";
            }
            return width;
        },
        delawareWidth() {
            let width = "";
            if (this.windowWidth < 770) {
                width = "160";
            } else if (this.windowWidth < 992) {
                width = "177.5";
            } else if (this.windowWidth > 992) {
                width = "213";
            }
            return width;
        },
        delawareHeight() {
            let width = "";
            if (this.windowWidth < 770) {
                width = "45";
            } else if (this.windowWidth < 992) {
                width = "48.292";
            } else if (this.windowWidth > 992) {
                width = "57.95";
            }
            return width;
        },
        protectWidth() {
            let width = "";
            if (this.windowWidth < 770) {
                width = "190";
            } else if (this.windowWidth < 992) {
                width = "219.167";
            } else if (this.windowWidth > 992) {
                width = "263";
            }
            return width;
        },
        protectHeight() {
            let width = "";
            if (this.windowWidth < 770) {
                width = "36";
            } else if (this.windowWidth < 992) {
                width = "46.509";
            } else if (this.windowWidth > 992) {
                width = "55.811";
            }
            return width;
        },
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        goToForm() {
            // Construct the URL with form data and UTM values
            const formUrl = "https://www.advisorinternetmarketing.com/form/";
            const queryParams = new URLSearchParams();
            let parametersAppended = false;

            // Append form data to URL
            for (const key in this.formData) {
                // Check if the value is not empty or undefined
                if (
                    this.formData[key] !== "" &&
                    this.formData[key] !== undefined
                ) {
                    queryParams.append(key, this.formData[key]);
                    parametersAppended = true;
                }
            }

            // Append UTM values to URL if they exist in formData
            if (this.formData.utm_source) {
                queryParams.append("utm_source", this.formData.utm_source);
                parametersAppended = true;
            }
            if (this.formData.utm_medium) {
                queryParams.append("utm_medium", this.formData.utm_medium);
                parametersAppended = true;
            }
            if (this.formData.utm_campaign) {
                queryParams.append("utm_campaign", this.formData.utm_campaign);
                parametersAppended = true;
            }
            if (this.formData.utm_term) {
                queryParams.append("utm_term", this.formData.utm_term);
                parametersAppended = true;
            }
            if (this.formData.utm_content) {
                queryParams.append("utm_content", this.formData.utm_content);
                parametersAppended = true;
            }
            if (this.formData.gclid) {
                queryParams.append("gclid", this.formData.gclid);
                parametersAppended = true;
            }

            // Concatenate URL with query parameters
            let urlWithParams = formUrl;
            if (parametersAppended) {
                urlWithParams += `?${queryParams.toString()}`;
            }

            // Navigate to the constructed URL
            window.location.href = urlWithParams;
        },
    },
    created() {
        window.addEventListener("resize", this.handleResize);
    },
    unmounted() {
        window.removeEventListener("resize", this.handleResize);
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            texts: [
                {
                    text: "If you’re looking to predictably grow your annuity production, while better serving your clients, then we may be the right FMO for you.",
                },
                {
                    text: "We are seeing advisors write more annuity business than ever before.",
                },
                {
                    text: "And you can too…",
                },
                {
                    text: "…but only if you have access to a marketing system and a proven virtual sales process.",
                },
                {
                    text: "Sadly, many of the FMOs are still pushing the same annuities and old-school methods they’ve been promoting for years, giving little to no thought about what’s best for your clients.",
                },
                {
                    text: "We think there is a better way to build a wildly profitable advisory practice.",
                },
                {
                    text: "AIM is the first and only FMO that helps advisors grow a wildly profitable location-independent business with just an Internet connection and a cell phone.",
                },
                {
                    text: "Let’s face it, working with the right FMO/IMO could be the difference between you staying stuck where you are now… and you building a profitable financial practice that supports your dream lifestyle.",
                },
                {
                    text: "During your Discovery Call, we’ll review your business and aspirational goals and see how we can help. In the meantime, feel free to continue gathering additional information on this page.",
                },
                {
                    text: "Talk soon,",
                },
                {
                    text: "- Jovan Will & Fernando Godinez",
                },
                {
                    text: "P.S. If you decide to work with us to grow your practice, here's what we'll focus on...",
                    type: "bold",
                },
                {
                    html: "<b>#1 - Digital Marketing:</b> While most FMOs are still pushing traditional and outdated marketing methods, which are slow & unpredictable, we use digital marketing to target and attract qualified annuity prospects.",
                    custom: true,
                },
                {
                    text: "We generate targeted annuity prospects and scheduled appointments — all on autopilot.",
                },
                {
                    text: "Advisors are able to plug into a fully turnkey, done-for-you system that predictably attracts qualified prospects without any complicated tech setup or implementation headaches.",
                },
                {
                    text: "For every $1 our advisors spend on advertising, they are able to generate $3-10 in commissions.",
                },
                {
                    text: "Advisors are able to buy targeted annuity leads for $350 per lead, & then begin to scale as they close more annuity business.",
                },
                {
                    html: "<b>#2 - Virtual Sales:</b>  While most FMOs are still pushing advisors to host dinner seminars, educational classes and meet with clients in-person — our advisors RARELY, if ever, meet with clients face-to-face.",
                    custom: true,
                },
                {
                    text: "Thereby eliminating the need of having a fancy office, large staff, or any in-person meetings with prospects and clients.",
                },
                {
                    text: "Because we use our proven “Advisor Freedom Formula” to rapidly build trust & authority with each prospect, handle their objections in advance, & ethically and easily convert prospects into clients without being pushy, slimy, or salesy.",
                },
                {
                    html: "<b>#3 - Best Annuities:</b> We have strategically partnered with the industry’s #1 FMO, Advisors Excel, to provide our advisors with world-class back office support, illustration, and case design services to get our advisors’ deals funded.",
                    custom: true,
                },
                {
                    text: "We help advisors gain an ethical and unfair advantage over their competition by providing them the best-in-business annuities, including limited distribution and proprietary products that less than 1% of advisors have access to.",
                },
                {
                    text: "Our advisors can go into each meeting with prospects/clients knowing they have access to the best virtual sales training, best product options, and back office to help them better serve their clients.",
                },
                {
                    text: "What's next?",
                },
            ],
            bullets1: [
                {
                    title: " Done-For-You Lead Generation",
                    text: "Our flagship digital marketing program generates targeted annuity leads with qualified prospects, 100% turnkey and fully automated.",
                    icon: "team",
                },
                {
                    title: " Done-For-You Appointment Setting",
                    text: "We drive annuity prospects to schedule appointments with our advisors through our outbound schedulers and automated campaigns. Advisors can rely on our team to contact all their leads and book appointments.",
                    icon: "calendarCheck",
                },
                {
                    title: " Done-For-You Reports/White Papers",
                    text: "Advisors get access to our content library of annuity reports, retirement guides, and white papers that can be used to educate prospects and clients.",
                    icon: "report",
                },
                {
                    title: " Personalized Sales Funnels",
                    text: "We build fully customized campaigns and funnels for each advisor to encourage prospects to know, like, and trust you BEFORE you meet with them. We want to showcase your background, credentials, and expertise.",
                    icon: "funnel",
                },
                {
                    title: " Automated Nurture Campaigns",
                    text: "Our system will keep prospects engaged and stay “top of mind” by sending out ongoing, fresh new content (videos, webinars, blog posts) designed to educate and inspire prospects to take action. These campaigns are fully automated and personal to each advisor and include calls-to-action linked directly to book on your calendar.",
                    icon: "robot",
                },
                {
                    title: " Custom CRM Lead Management",
                    text: "All of the advisors’ annuity leads are neatly organized in our custom CRM which enables them to add notes, manage the sales pipeline and share feedback with our appointment-setting team.",
                    icon: "teamCog",
                },
                {
                    title: " Step-By-Step Virtual Sales Training",
                    text: "Get your hands on our proven virtual sales process that enables advisors to confidently close new business while delivering massive value to clients without in-person meetings. (Each video in our curriculum is only 10-15 minutes long & comes with an action guide - some of our most successful advisors implement the training with existing clients to start business right away).",
                    icon: "grad",
                },
                {
                    title: " Gather & Showcase Client Reviews",
                    text: "Our system automatically gathers ratings, reviews, and testimonials from our advisors’ clients. These reviews are prominently featured online and help advisors build trust with prospects and clients. Experience the power of client reviews to elevate your positioning and authority.",
                    icon: "starFilled",
                },
                {
                    title: " Live Mastermind Session/Week",
                    text: "Our Inner Circle is a collective mastermind of top virtual advisors from around the country. We host a live mastermind Zoom call once a week to empower advisors with advanced annuity strategies to maximize the income and benefits of their clients.",
                    icon: "videocam",
                },
                {
                    title: " Top Annuities/Proprietary Products",
                    text: "Our annuity product selection equips advisors with the best-in-business limited distribution and proprietary products that less than 1% of advisors have access to.",
                    icon: "starChart",
                },
                {
                    title: " World-Class Back Office",
                    text: "We have strategically partnered with the industry’s #1 FMO, Advisors Excel, to provide our advisors with world-class back office support, illustration, and case design services to get our advisors’ deals funded.",
                    icon: "building",
                },
            ],
            bullets2: [
                {
                    title: " Live Advisor Training Events",
                    text: "Our advisors are invited to attend our live in-person training event where we gather together, celebrate wins, share ideas and break bread together.",
                    icon: "ticket",
                },
                {
                    title: " Incentive Trips",
                    text: "Qualifying advisors are invited to our annual incentive trip to exotic destinations. Our incentive trips are a world-class experience where advisors can connect with one another and celebrate their sales accomplishments.",
                    icon: "plane",
                },
            ],
        };
    },
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";

#bullet-column-section {
    padding: 15px 1rem 45px;
    background-color: var(--white);
    @media (min-width: $breakpoint-mobile) {
        padding: 4rem 1rem 5rem;
    }
    @media (min-width: $breakpoint-tablet) {
        padding: 5rem 0 8rem;
    }
    > .container {
        max-width: 1150px;
        padding-top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (min-width: $breakpoint-desktop) {
            max-width: 80vw;
        }
        .logo-section {
            margin-bottom: 2.5rem;
            @media (min-width: $breakpoint-mobile) {
                max-width: 51.875rem;
                margin-bottom: 4rem;
            }
            @media (min-width: $breakpoint-tablet) {
                max-width: 996px;
                margin-bottom: 5rem;
            }
            @media (min-width: $breakpoint-desktop) {
                max-width: 80vw;
            }
            .title-logo {
                font-size: 1.5rem;
                color: var(--title-blue);
                font-family: $font-family-black;
                font-weight: 700;
                text-transform: uppercase;
                @media (min-width: $breakpoint-mobile) {
                    font-size: 2.34375rem;
                    line-height: normal;
                }
                @media (min-width: $breakpoint-tablet) {
                    font-size: 2.8125rem;
                    line-height: normal;
                }
            }
            .subtitle {
                font-family: $font-family-inter;
                color: var(--text-blue);
                font-size: 1.1rem;
                line-height: 1.3em;
                letter-spacing: 0.5px;
                margin-bottom: 0;
                @media (min-width: $breakpoint-mobile) {
                    font-size: 1.04169rem;
                    line-height: normal;
                }
                @media (min-width: $breakpoint-tablet) {
                    font-size: 1.25rem;
                    line-height: normal;
                    padding: 0 3%;
                }
            }
        }
        .img-section {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 2.5rem;
            @media (min-width: $breakpoint-mobile) {
                justify-content: space-between;
                margin-bottom: 4rem;
            }
            @media (min-width: $breakpoint-tablet) {
                flex-direction: row;
                justify-content: space-around;
                margin-bottom: 40px;
                width: 74.9rem;
                margin-bottom: 8.82rem;
            }
            @media (min-width: $breakpoint-desktop) {
                width: 80vw;
            }
        }
        > .row {
            > .left-side {
                padding: 0 0.5rem;
                @media (min-width: $breakpoint-mobile) {
                    padding: 0 1rem;
                }
                @media (min-width: $breakpoint-tablet) {
                    width: 580px;
                    min-width: 400px;
                    padding: 0;
                }
                @media (min-width: $breakpoint-desktop) {
                    width:50%
                }
                > .card-section {
                    padding: 3rem 1.3rem;
                    background-color: var(--bg-light-blue);
                    border-radius: 10px;
                    height: fit-content;
                    display: flex;
                    flex-direction: column;
                    @media (min-width: $breakpoint-mobile) {
                        padding: 4.17rem 1.3rem;
                    }
                    @media (min-width: $breakpoint-tablet) {
                        padding: 5rem 2.5rem 5rem;
                    }
                    .title {
                        font-size: 1.75rem;
                        font-weight: 700;
                        text-align: left;
                        margin-top: 0;
                        font-family: $font-family-black;
                        margin-bottom: 0;
                        line-height: normal;
                        text-transform: uppercase;
                        color: var(--title-blue);
                        align-self: stretch;
                        @media (min-width: $breakpoint-mobile) {
                            font-size: 2rem;
                            line-height: normal;
                        }
                        @media (min-width: $breakpoint-tablet) {
                            font-size: 2.813rem;
                            line-height: normal;
                        }
                    }
                    .texts {
                        font-family: $font-family-inter;
                    }
                    .link {
                        text-align: left;
                        color: var(--link-blue);
                        float: left;
                        font-size: 1rem;
                        font-family: $font-family-inter;
                        font-weight: 400;
                        line-height: 1.5rem;
                        margin: 3% auto;
                        @media (min-width: $breakpoint-mobile) {
                            font-size: 1.04169rem;
                        }
                        @media (min-width: $breakpoint-tablet) {
                            font-size: 1.25rem;
                            line-height: 1.513rem;
                            letter-spacing: 0.1px;
                        }
                        .link-anchor {
                            color: var(--link-blue);
                            text-align: left;
                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                    .subtext {
                        color: var(--text-blue);
                        font-size: 1.125rem;
                        text-align: left;
                        font-family: $font-family;
                        font-weight: 500;
                        line-height: 1.3em;

                        @media (min-width: $breakpoint-mobile) {
                        }
                        @media (min-width: $breakpoint-tablet) {
                            font-size: 1.25rem;
                        }
                    }
                }
            }
            > .right-side {
                margin-top: 0;
                padding: 0 0.5rem;
                @media (max-width: $breakpoint-mobile) {
                }
                @media (min-width: $breakpoint-tablet) {
                    padding-left: 2.5rem;
                    padding-right: 0;
                    width: 580px;
                    min-width: 400px;
                }
                @media (min-width: $breakpoint-desktop) {
                    width:50%
                }
            }
        }

        #popup-button {
            @media (max-width: $breakpoint-mobile) {
                padding: 0;
            }
            .book-link {
                color: var(--text-blue);
            }

            .book-link:hover {
                text-decoration: underline;
            }
        }

        .btn {
            @media (max-width: $breakpoint-mobile) {
                font-size: 13px;
            }
        }
    }
}
</style>
