// src/router.js
import { createRouter, createWebHistory } from "vue-router";

// Import your Vue components for the routes
import Call from "./views/Call.vue";
const routes = [
    {
        path: "/call",
        name: "call",
        component: Call,
        meta: {
            title: "Want Us To Help You Close More Annuity Business  And Grow Your Practice, Without Sacrificing Your Lifestyle?", // Set the title for the home route
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    // Scroll to the top of the page
    console.log("to", to);
    console.log("from", from);
    window.scrollTo(0, 0);
    document.title = to.meta.title || 'Advisor Internet Marketing';
    // Continue to the next route
    next();
});

export default router;
