<template>
    <footer id="footer">
        <div class="container">
            <p>
                Copyright © {{ currentYear }} Advisor Internet Marketing. All
                Rights Reserved.
            </p>
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterSection",
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    },
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";

#footer {
    background-color: var(--bg-light-blue);
    height: 60px;
    padding: 0.5rem;
    display: flex;
    align-items: center;

    > .container {
        max-width: 100%;

        @media (min-width: $breakpoint-mobile) {
            max-width: 1170px;
            padding: 0;
        }

        p {
            font-family: $font-family-inter;
            color: var(--black);
            font-size: 1rem;
            line-height: 1.3rem;
            margin: 0;
        }
    }
}
</style>
