<template>
    <section id="popup-button">
        <div class="container" v-if="justLink">
            <div class="btn-book-link">
                <a
                    class="blue-book-link"
                    href="javascript:void(0);"
                    @click="goToForm"
                    v-html="linkText"
                ></a>
            </div>
        </div>

        <div class="container" v-else>
            <button class="btn" @click="goToForm">
                Book Your Discovery Phone Call Now
            </button>
            <div class="btn-book-link">
                <a
                    class="book-link"
                    href="javascript:void(0);"
                    @click="goToForm"
                    :class="{ 'text-white': isWhite }"
                    >Tap here to book your discovery phone call</a
                >
            </div>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: "ButtonComp",
    props: {
        justLink: {
            type: Boolean,
            default: false,
        },
        linkText: String,
        isWhite: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState(['formData'])
    },
    methods: {
        goToForm() {
            // Construct the URL with form data and UTM values
            const formUrl = "https://www.advisorinternetmarketing.com/form/";
            const queryParams = new URLSearchParams();
            let parametersAppended = false;

            // Append form data to URL
            for (const key in this.formData) {
                // Check if the value is not empty or undefined
                if (this.formData[key] !== "" && this.formData[key] !== undefined) {
                    queryParams.append(key, this.formData[key]);
                    parametersAppended = true;
                }
            }
            
            // Append UTM values to URL if they exist in formData
            if (this.formData.utm_source) {
                queryParams.append('utm_source', this.formData.utm_source);
                parametersAppended = true;
            }
            if (this.formData.utm_medium) {
                queryParams.append('utm_medium', this.formData.utm_medium);
                parametersAppended = true;
            }
            if (this.formData.utm_campaign) {
                queryParams.append('utm_campaign', this.formData.utm_campaign);
                parametersAppended = true;
            }
            if (this.formData.utm_term) {
                queryParams.append('utm_term', this.formData.utm_term);
                parametersAppended = true;
            }
            if (this.formData.utm_content) {
                queryParams.append('utm_content', this.formData.utm_content);
                parametersAppended = true;
            }
            if (this.formData.gclid) {
                queryParams.append('gclid', this.formData.gclid);
                parametersAppended = true;
            }

            // Concatenate URL with query parameters
            let urlWithParams = formUrl;
            if (parametersAppended) {
                urlWithParams += `?${queryParams.toString()}`;
            }

            // Navigate to the constructed URL
            window.location.href = urlWithParams;
        }
    },
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";

#popup-button {
    > .container {
        margin-top: 1.5rem;
        max-width: 100%;
        padding: 0;
        @media (min-width: $breakpoint-mobile) {
            max-width: 49.3rem;
        }
        @media (min-width: $breakpoint-tablet) {
            max-width: 62rem;
            margin-top: 2.5rem;
        }

        .btn {
            background-color: var(--yellow);
            box-shadow: 0px 0.763px 0px 0.763px rgba(255, 255, 255, 0.2) inset;
            border: 0.916px solid rgba(0, 0, 0, 0.2);
            border-radius: 8.33px;
            width: 100%;
            height: 100%;
            padding: 1.03044rem 1.52844rem 1.07969rem 1.54563rem;
            color: var(--dark-black);
            text-transform: uppercase;
            font-family: $font-family-black;
            font-size: 0.95rem;
            line-height: 1.3em;
            font-weight: 900;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            @media (min-width: $breakpoint-mobile) {
                font-size: 2rem;
                padding: 0.85869rem 1.27369rem 0.89975rem 1.28806rem;
            }
            @media (min-width: $breakpoint-tablet) {
                padding: 1.03044rem 1.6rem 1.07969rem 1.6rem;
                max-height: 95px;
                height: auto; // Remove the height: 100%;
                line-height: 5.179rem;
                font-size: 2.4rem;
            }
        }
        .btn:hover {
            cursor: pointer;
        }

        .btn-book-link {
            margin-top: 0.8rem;
            @media (min-width: $breakpoint-mobile) {
                margin-top: 1.16rem;
            }
            @media (min-width: $breakpoint-tablet) {
                margin-top: 2rem;
            }

            .book-link {
                color: var(--white);
                font-family: $font-family-inter;
                font-weight: 400;
                font-size: 0.85rem;
                line-height: 1.788rem;
                margin: 0;
                text-decoration: underline;
                text-underline-position: under;
                text-decoration-thickness: from-font;
                padding: 0;
                letter-spacing: 0.417px;

                @media (min-width: $breakpoint-mobile) {
                    font-size: 1.04169rem;
                    line-height: normal;
                }
                @media (min-width: $breakpoint-tablet) {
                    font-size: 1.25rem;
                    line-height: normal;
                }
            }

            .blue-book-link {
                color: #1781f1;
                font-family: $font-family-inter;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.788rem;
                margin: 0;
                text-decoration: underline;
                padding: 10px 20px;
                text-underline-position: under;
                text-decoration-thickness: from-font;
                letter-spacing: 0.417px;

                @media (min-width: $breakpoint-mobile) {
                    font-size: 1.375rem;
                    line-height: 2.188rem;
                }
            }
            .blue-book-link:hover {
                text-decoration: none;
            }
        }
    }
}
</style>
