<template>
    <section id="main-video-section">
        <div class="container">
            <TitleComp 
                topSubtitle="An Exclusive Opportunity For Annuity Producers Writing At Least $2-3M In Annual Annuity Production..." 
                topSubtitleClass="header-topSubtitleClass"
                title="Want Us To Help You Grow Your Practice, Without Sacrificing Your Lifestyle?" 
                titleClass="header-TitleClass"
                bottomSubtitle="(No Office, No Dinner Seminars, No Staff, No Problem!)" 
                bottomSubtitleClass="header-bottomSubtitleClass"
            />
            <div class="video-section">
                <WistiaVideo videoId="t5e71exh7x" />
            </div>
            

            <PopButtonComp @click="$emit('click')" />
        </div>
    </section>
</template>

<script>
import {mapActions} from 'vuex'

import TitleComp from './comps/TitleComp.vue';
import WistiaVideo from './comps/WistiaVideo.vue';
import PopButtonComp from './comps/PopButtonComp.vue';

export default {
    name: 'MainVideoSection',
    components: {
        TitleComp,
        WistiaVideo,
        PopButtonComp,
    },
    methods: {
        ...mapActions(['toggleModal']),
    }
}
</script>

<style lang="scss">
@import "@/assets/css/main.scss";

#main-video-section {
    background: var(--dark-blue);
    margin-top: -5px;
    padding: 1.2rem 0 2rem;
    @media (min-width: $breakpoint-mobile) {
        padding: 3.75rem 0 7.34rem;
    }
    @media (min-width: $breakpoint-tablet) {
        padding: 3.8rem 0 8.68rem;
        background: radial-gradient(69.45% 42.25% at 50% 50%, #083E91 0%, #092B60 49%, #0B1A2B 100%);
    }

    > .container {
        max-width: 100%;
        padding: 0 16px;
        
        @media (min-width: $breakpoint-mobile) {
            padding: 0;
            max-width: 80vw;
        }
        .video-section {
            margin: 0 auto;
            @media(min-width: $breakpoint-mobile) {
                padding: 0;
            }
            @media(min-width: $breakpoint-tablet) {
                padding: 0 5.5rem;
            }
        }
        .header-topSubtitleClass {
            margin: 0;
            font-size: 0.68rem !important;
            line-height: 1.3em;
            color: var(--yellow);
            text-transform: uppercase;
            font-family: $font-family-inter; 
            margin-top: 0;
            max-width: 350px;
            @media(min-width: $breakpoint-mobile) {
                font-size: 1.04169rem !important;
                line-height: normal;
                letter-spacing: 0.083px;
                max-width: 541px;
            }
            @media (min-width: $breakpoint-tablet) {
                font-size: 1.25rem !important;
                line-height: normal;
                letter-spacing: 0.1px;
                max-width: 650px;
            }
        }
        .header-TitleClass {
            font-size: 1.75rem !important;
            line-height: 1.3em;
            color: var(--white);
            margin: 5px 0;
            font-family: $font-family-black;
            text-transform: uppercase;
            font-weight: 700;
            @media(min-width: $breakpoint-mobile) {
                font-size: 2.34375rem;
                margin: 7px 0;
            }
            @media (min-width: $breakpoint-tablet) {
                font-size: 2.813rem !important;

            }
        }
        .header-bottomSubtitleClass {
            font-size: 0.75rem !important;
            color: var(--white);
            font-family: $font-family-inter;
            margin: 0;
            @media (min-width: $breakpoint-mobile) {
                font-size: 1.04169rem;
                letter-spacing: 0.417px;
                margin-bottom: 2rem;
            }
            @media (min-width: $breakpoint-tablet) {
                font-size: 1.25rem !important;
                margin-bottom: 2.5rem;
                line-height: normal;
            }
        }

        #popup-button > .container .btn-book-link {
            .book-link {
                color: var(--white);
            }
        }
    }
}
</style>