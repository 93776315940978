// store.js
import createPersistedState from "vuex-persistedstate";

export default {
    state: {
        isModalVisible: false,
        formData: {},
    },

    mutations: {
        showModal(state) {
            state.isModalVisible = true;
        },
        hideModal(state) {
            state.isModalVisible = false;
        },
        SET_FORM_DATA(state, formData) {
            state.formData = formData; // Update the form data in the state
          },
    },
    actions: {
        toggleModal({ commit, state }) {
            if (state.isModalVisible) {
                commit("hideModal");
            } else {
                commit("showModal");
            }
        },
        setFormData({commit}, formData) {
            commit('SET_FORM_DATA',formData)
          },
    },
    plugins: [createPersistedState()],
};
