<template>
    <section id="booking">
        <div class="container">
            <div class="title-container">
                <TitleComp
                    topSubtitle="WHAT'S YOUR NEXT STEP?"
                    topSubtitleClass="text-topSubtitleClass"
                    :title="
                        isMobile
                            ? `Book Your 1:1 Discovery Call Today & Get This <span class='yellow-line'> Free Bonus… </span>`
                            : `Book Your 1:1 Discovery Call Today <br> & Get This <span class='yellow-line'> Free Bonus… </span>`
                    "
                    titleClass="text-TitleClass"
                />
                <!-- <ImageComp
                    imageName="yellow-line"
                    imgClass="img-yellow-line"
                    width="302"
                    height="19"
                /> -->
            </div>

            <div class="white-card">
                <TitleComp
                    title="Done-For-You Marketing Masterclass"
                    titleClass="card-TitleClass"
                    bottomSubtitle="See How To Predictably Close Annuity Business With Just An Internet Connection And A Cell Phone"
                    bottomSubtitleClass="card-bottomSubtitleClass"
                />

                <ImageComp imageName="booking" width="786" height="493" />

                <ParagraphComp :texts="text.p1" />
                <ParagraphComp :texts="text.p2" />

                <ul id="booking-list">
                    <li>
                        <SvgIcon name="arrowRight" fillColor="#FDC500" />
                        <p>
                            <strong>Say Goodbye To Dinner Seminars:</strong> See
                            the types of prospects we target and how we
                            consistently generate highly targeted traffic,
                            leads, and appointments.
                        </p>
                    </li>
                    <li>
                        <SvgIcon name="arrowRight" fillColor="#FDC500" />
                        <p>
                            <strong>Intelligent Sales Funnels:</strong> See how
                            we use “psychic sales sequences” to indoctrinate and
                            predict EXACTLY what our prospects want (and give it
                            to them).
                        </p>
                    </li>
                    <li>
                        <SvgIcon name="arrowRight" fillColor="#FDC500" />
                        <p>
                            <strong>Automated Leads & Appointments:</strong> The technology tools
                            and methods we use to convert leads into warm
                            appointments on autopilot.
                        </p>
                    </li>
                    <li>
                        <SvgIcon name="arrowRight" fillColor="#FDC500" />
                        <p>
                            <strong>Virtual Sales Strategies:</strong> Get access to our proven
                            virtual sales process and advanced annuity
                            strategies to gain an unfair and ethical advantage
                            over your competition.
                        </p>
                    </li>
                </ul>

                <div id="book-container">
                    <TitleComp
                        title="Book Your Discovery Call Today & Get Our Done-For-You Marketing Masterclass For Free…"
                        titleClass="book-titleClass"
                    />

                    <PopButtonComp
                        linkText="Tap here to book your discovery phone call"
                        justLink
                        @click="$emit('click')"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TitleComp from "./comps/TitleComp.vue";
import ImageComp from "./comps/ImageComp.vue";
import ParagraphComp from "./comps/ParagraphComp.vue";
import PopButtonComp from "./comps/PopButtonComp.vue";
import SvgIcon from "./comps/SvgIcon.vue";

export default {
    name: "BookingSection",
    components: {
        TitleComp,
        ImageComp,
        ParagraphComp,
        PopButtonComp,
        SvgIcon,
    },
    computed: {
        isMobile() {
            return this.windowWidth <= 770;
        },
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            text: {
                p1: [
                    {
                        text: "Over the past 6 years, we have helped advisors attract endless annuity prospects and clients automatically. This is the EXACT process we use to submit hundreds of millions of dollars in 2023 by doing the exact opposite of what everyone else is doing.",
                    },
                ],
                p2: [
                    {
                        text: "We recorded a 70-minute done-for-you marketing masterclass revealing exactly how we do it...",
                    },
                ],
            },
        };
    },
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";

#booking {
    background: var(--bg-dark-blue);
    padding: 45px 16px;

    @media (min-width: $breakpoint-mobile) {
        background: linear-gradient(180deg, #042348 0%, #010d19 100%);
        padding: 116px 1rem;
    }
    @media (min-width: $breakpoint-tablet) {
        padding: 116px 0;
    }
    > .container {
        max-width: 100%;
        padding: 0;

        @media (min-width: $breakpoint-mobile) {
            max-width: 1170px;
        }
        @media (min-width: $breakpoint-tablet) {
            max-width: 80vw;
        }

        .title-container {
            position: relative;
            margin-bottom: 2rem;

            @media (min-width: $breakpoint-mobile) {
                margin-bottom: 4rem;
            }

            #title-box-component {
                margin-bottom: 0;
            }

            .text-topSubtitleClass {
                color: var(--white);
                font-size: 1rem !important;

                @media (min-width: $breakpoint-mobile) {
                    font-size: 1.042rem !important;
                }
                @media (min-width: $breakpoint-tablet) {
                    font-size: 1.25rem !important;
                }
            }

            .text-TitleClass {
                color: var(--white);
                font-size: 1.35rem !important;
                line-height: 1.3em;
                font-family: $font-family-black;
                text-transform: uppercase;
                margin-top: 1rem;

                @media (min-width: $breakpoint-mobile) {
                    font-size: 2.344rem !important;
                    padding: 0 1.5rem;
                }
                @media (min-width: $breakpoint-tablet) {
                    font-size: 2.8125rem !important;
                }
                & .yellow-line {
					&::after {
						content: "";
						display: block;
						width: 100%;
						height: 20px;
						background-size: contain;
						background-repeat: no-repeat;
						background-position: calc(70%) bottom;
						background-image: url("../assets/img/yellow-line.webp");
						
						@media (min-width: $breakpoint-xs) {
						height: 10px;
						background-size: 40%;
						background-position: calc(65%) bottom;
						}

						@media (min-width: $breakpoint-mobile) {
						background-size: 40%;
						background-position: calc(65%) bottom;
						}
						@media (min-width: $breakpoint-tablet) {
						background-size: 30%;
						background-position: calc(70%) bottom;
						}
					}
				}
            }

            .img-yellow-line {
                // display: none;

                @media (min-width: $breakpoint-mobile) {
                    display: block;
                    position: absolute;
                    right: 320px;
                    bottom: 0px;
                }
                @media (max-width: $breakpoint-tablet) {
                    display: none;
                }
            }

            .image {
                width: 150px;
                height: 10px;

                @media (min-width: $breakpoint-mobile) {
                    width: 302px;
                    height: 19px;
                }
            }
        }

        .white-card {
            background-color: var(--white);
            padding: 1rem 1rem 3rem;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
            border-radius: 10px;

            @media (min-width: $breakpoint-mobile) {
                padding: 3rem 1rem 5.5rem;
                max-width: 1150px;
                margin: 1rem auto;
            }
            @media (min-width: $breakpoint-tablet) {
                max-width: 80vw;
            }

            #title-box-component {
                padding: 0;
                margin: 2rem 0;

                @media (min-width: $breakpoint-mobile) {
                    padding: 0 4rem;
                }

                .card-TitleClass {
                    font-size: 1.7rem !important;
                    line-height: 1em;
                    font-family: $font-family-black;
                    text-transform: uppercase;
                    max-width: 80%;
                    margin: 0 auto;
                    color: var(--dark-blue);

                    @media (min-width: $breakpoint-mobile) {
                        font-size: 2.344rem !important;
                        max-width: 100%;
                    }
                    @media (min-width: $breakpoint-tablet) {
                        font-size: 2.8125rem !important;
                    }
                }

                .card-bottomSubtitleClass {
                    font-size: 1rem !important;
                    margin: 1rem auto 0;
                    font-family: $font-family-bold;
                    max-width: 100%;
                    color: var(--dark-blue);

                    @media (min-width: $breakpoint-mobile) {
                        max-width: 95%;
                        font-size: 1.563rem !important;
                    }
                    @media (min-width: $breakpoint-tablet) {
                        max-width: 90%;
                        font-size: 1.875rem !important;
                    }
                }
            }

            .book-titleClass {
                font-size: 1.5rem !important;
                font-family: $font-family-black;
                text-transform: uppercase;

                @media (min-width: $breakpoint-mobile) {
                    font-size: 2.1rem !important;
                }
                @media (min-width: $breakpoint-tablet) {
                    font-size: 2.68rem !important;
                }
            }

            #image-component {
                > .container {
                    padding: 0;
                    display: flex;
                    justify-content: center;
                }
            }

            #paragraph {
                > .container {
                    max-width: 100%;
                    padding: 0;

                    @media (min-width: $breakpoint-mobile) {
                        padding: 0 3rem;
                    }
                }

                .p {
                    font-size: 1.125rem;
                    line-height: 1.607rem;
                    font-weight: 400;
                    font-family: $font-family-inter;
                    color: var(--black);

                    @media (min-width: $breakpoint-mobile) {
                        font-size: 1.042rem;
                        line-height: 1.513rem;
                    }
                }
            }

            #booking-list {
                margin-top: -1rem;
                padding: 0;

                @media (min-width: $breakpoint-mobile) {
                    padding: 0 3rem;
                }

                li {
                    display: flex;
                    align-items: baseline;
                    list-style: none;

                    .svg-icon svg {
                        width: 18px;
                        height: 18px;

                        @media (min-width: $breakpoint-mobile) {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    p {
                        font-family: $font-family-inter;
                        text-align: left;
                        font-size: 1.042rem !important;
                        line-height: 1.3em;
                        margin: 0.5rem 0 0.5rem 1rem;

                        @media (min-width: $breakpoint-mobile) {
                            line-height: 1.513rem;
                        }
                    }
                }
            }

            #book-container {
                padding: 0;

                @media (min-width: $breakpoint-mobile) {
                    padding: 0 20px;
                }

                #title-box-component {
                    margin-bottom: 0;
                }

                #popup-button > .container {
                    margin-top: 0;
                }

                #popup-button > .container .btn-book-link {
                    margin-top: 1rem;

                    .blue-book-link {
                        text-align: center;
                        padding: 0;
                        font-size: 0.85rem;
                        @media (min-width: $breakpoint-mobile) {
                            font-size: 1.04169rem;
                        }
                        @media (min-width: $breakpoint-tablet) {
                            font-size: 1.25rem;
                        }
                    }
                }
            }
        }
    }
}
</style>
