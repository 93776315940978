<template>
    <section id="video-images-section">
        <div class="container">
            <TitleComp 
                topSubtitle="DON'T JUST TAKE OUR WORD FOR IT..." 
                topSubtitleClass="text-topSubtitleClass"
                title="What Do Our Advisors Have To Say?" 
                titleClass="text-TitleClass"
                bottomSubtitle="See A Few Of Our Most Recent Success Stories Below..."
                bottomSubtitleClass="text-bottomSubtitleClass"
            />
            <hr class="hr-yellow-1">

            <div class="video-images-section">
                <VideoImages 
                title="Mike Ditched Dinner Seminars And Live Events, Built A $10M Practice, And Gathered Over $15M In AUM After Partnering With AIM"
                videoId="msg78c1p4r"
                subtitle="Here’s what Mike’s clients have to say about working with him…"
                :imagesLeft="images.Mike.left" 
                :imagesRight="images.Mike.right"
                />
                <hr class="hr-yellow">

                <VideoImages 
                title="Former MMA Cage Fighter, Sean And His Newly Licensed Father, Built A $10M Practice And Gathered $4M In AUM In  2022"
                videoId="bq06w7mb48"
                subtitle="Here’s what Sean’s clients have to say about working with him…"
                :imagesLeft="images.Sean.left" 
                :imagesRight="images.Sean.right"/>
                <hr class="hr-yellow">

                <VideoImages 
                title="Former Teacher Turned Advisor, Barbara, Built A Thriving $3M Virtual Practice, Without Sacrificing Her Health Or Family"
                videoId="i32zxib22a"
                subtitle="Here’s what Barbara’s clients have to say about working with her…"
                :imagesLeft="images.Barbara.left" 
                :imagesRight="images.Barbara.right"/>
                <hr class="hr-yellow">

                <VideoImages 
                title="Brian, A Part-Time Advisor, Built A $3M Financial Practice And Earns More Than His Full-Time Job, Working Only 10-15 Hours a Week"
                videoId="6tna2c1lu9"/>
                <hr class="hr-yellow">

                <VideoImages 
                title="Terry Closed 2 Annuity Cases Within 60 Days Of Working With Us"
                videoId="i5yh1irngq"
                subtitle="Here’s what Terry’s clients have to say about working with him…"
                :imagesLeft="images.Terry.left" 
                :imagesRight="images.Terry.right"
                isTerry/>
            </div>
        </div>
    </section>
</template>

<script>
import TitleComp from './comps/TitleComp.vue';
import VideoImages from './comps/VideoImages.vue';
export default {
    name: 'VideoImagesSection',
    components: {
        VideoImages,
        TitleComp,
    },
    data() {
        return {
            images: {
                Mike: {
                    left: [
                        {
                            name: "testimonials/Mike/T1",
                            alt: "Testimonial 1"
                        },
                        {
                            name: "testimonials/Mike/T3",
                            alt: "Testimonial 3"
                        },
                        {
                            name: "testimonials/Mike/T5",
                            alt: "Testimonial 5"
                        },
                        {
                            name: "testimonials/Mike/T7",
                            alt: "Testimonial 7"
                        },
                        {
                            name: "testimonials/Mike/T9",
                            alt: "Testimonial 9"
                        } 
                    ],
                    right: [
                    {
                            name: "testimonials/Mike/T2",
                            alt: "Testimonial 2"
                        },
                        {
                            name: "testimonials/Mike/T4",
                            alt: "Testimonial 4"
                        },
                        {
                            name: "testimonials/Mike/T6",
                            alt: "Testimonial 6"
                        },
                        {
                            name: "testimonials/Mike/T8",
                            alt: "Testimonial 8"
                        },
                        {
                            name: "testimonials/Mike/T10",
                            alt: "Testimonial 10"
                        }
                    ]
                },
                Sean: {
                    left: [
                        {
                            name: "testimonials/Mike/T1",
                            alt: "Testimonial 1"
                        },
                        {
                            name: "testimonials/Sean/T3",
                            alt: "Testimonial 3"
                        },
                        {
                            name: "testimonials/Sean/T2",
                            alt: "Testimonial 5"
                        },
                    ],
                    right: [
                    {
                            name: "testimonials/Sean/T2",
                            alt: "Testimonial 2"
                        },
                        {
                            name: "testimonials/Sean/T4",
                            alt: "Testimonial 4"
                        },
                        {
                            name: "testimonials/Mike/T1",
                            alt: "Testimonial 6"
                        },
                    ]
                },
                Barbara: {
                    left: [
                        {
                            name: "testimonials/Barbara/T1",
                            alt: "Testimonial 1"
                        },
                        {
                            name: "testimonials/Barbara/T3",
                            alt: "Testimonial 3"
                        },
                        {
                            name: "testimonials/Barbara/T5",
                            alt: "Testimonial 5"
                        },
                        {
                            name: "testimonials/Barbara/T7",
                            alt: "Testimonial 7"
                        },
                        {
                            name: "testimonials/Barbara/T9",
                            alt: "Testimonial 9"
                        },
                        {
                            name: "testimonials/Barbara/T11",
                            alt: "Testimonial 11"
                        },
                        {
                            name: "testimonials/Barbara/T13",
                            alt: "Testimonial 13"
                        }
                    ],
                    right: [
                    {
                            name: "testimonials/Barbara/T2",
                            alt: "Testimonial 2"
                        },
                        {
                            name: "testimonials/Barbara/T4",
                            alt: "Testimonial 4"
                        },
                        {
                            name: "testimonials/Barbara/T6",
                            alt: "Testimonial 6"
                        },
                        {
                            name: "testimonials/Barbara/T8",
                            alt: "Testimonial 8"
                        },
                        {
                            name: "testimonials/Barbara/T10",
                            alt: "Testimonial 10"
                        },
                        {
                            name: "testimonials/Barbara/T12",
                            alt: "Testimonial 12"
                        }
                    ]
                },
                Terry: {
                    left: [
                        {
                            name: "testimonials/Terry/T1",
                            alt: "Testimonial 1"
                        },
                        {
                            name: "testimonials/Terry/T3",
                            alt: "Testimonial 3"
                        },
                        {
                            name: "testimonials/Terry/T5",
                            alt: "Testimonial 5"
                        },
                        {
                            name: "testimonials/Terry/T7",
                            alt: "Testimonial 7"
                        },
                        {
                            name: "testimonials/Terry/T9",
                            alt: "Testimonial 9"
                        },
                        {
                            name: "testimonials/Terry/T11",
                            alt: "Testimonial 11"
                        }
                    ],
                    right: [
                    {
                            name: "testimonials/Terry/T2",
                            alt: "Testimonial 2"
                        },
                        {
                            name: "testimonials/Terry/T4",
                            alt: "Testimonial 4"
                        },
                        {
                            name: "testimonials/Terry/T6",
                            alt: "Testimonial 6"
                        },
                        {
                            name: "testimonials/Terry/T8",
                            alt: "Testimonial 8"
                        },
                        {
                            name: "testimonials/Terry/T10",
                            alt: "Testimonial 10"
                        }
                    ]
                },
                Advisors: {
                    left: [
                        {
                            name: "testimonials/AdvisorsClients/T1",
                            alt: "Testimonial 1"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T2",
                            alt: "Testimonial 2"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T3",
                            alt: "Testimonial 3"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T5",
                            alt: "Testimonial 5"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T6",
                            alt: "Testimonial 6"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T7",
                            alt: "Testimonial 7"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T8",
                            alt: "Testimonial 8"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T9",
                            alt: "Testimonial 9"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T15",
                            alt: "Testimonial 15"
                        },
                    ],
                    right: [
                    {
                            name: "testimonials/AdvisorsClients/T10",
                            alt: "Testimonial 10"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T11",
                            alt: "Testimonial 11"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T12",
                            alt: "Testimonial 12"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T13",
                            alt: "Testimonial 13"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T14",
                            alt: "Testimonial 14"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T16",
                            alt: "Testimonial 16"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T17",
                            alt: "Testimonial 17"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T18",
                            alt: "Testimonial 18"
                        },
                        {
                            name: "testimonials/AdvisorsClients/T19",
                            alt: "Testimonial 19"
                        },
                    ]
                }
            }
        }
    }
    
}
</script>

<style lang="scss">
@import "@/assets/css/main.scss";
#video-images-section {
    padding: 30px 0;
    background-color: var(--white);
    @media(min-width: $breakpoint-mobile) {
        padding: 5.3rem 0;
    }
    @media (min-width: $breakpoint-tablet) {
        padding: 7.38rem 0 8.11rem;
    }
    .container {
        width: 100%;
        @media (min-width: $breakpoint-desktop) {
            max-width: 80vw;
        }
        .text-topSubtitleClass {
            margin-top: 0;
            font-size: 0.8rem;
            font-family: $font-family-inter;
            font-weight: 500;
            color: var(--title-blue);
            
            @media(min-width: $breakpoint-mobile) {
                font-size: 1.04169rem;
                
            }
            @media (min-width: $breakpoint-tablet) {
                font-size: 1.25rem;
                line-height: 1.513rem;
            }
        }
        .text-TitleClass {
            font-size: 2rem;
            line-height: 2.5rem;
            font-family: $font-family-black;
            text-transform: capitalize;
            color: var(--title-blue);
            margin: 3% auto 4%;
            @media(min-width: $breakpoint-mobile) {
                font-size: 3rem;
                margin: 1.25rem auto;
            }
            @media (min-width: $breakpoint-tablet) {
                font-size: 4.15em;
                line-height: 5.403rem;
                padding: auto;
                margin: 1% auto;
            }
        }
        .text-bottomSubtitleClass {
            font-size: 1.4rem;
            font-family: $font-family-inter;
            font-weight: 500;
            text-transform: capitalize;
            color: var(--title-blue);
            @media(min-width: $breakpoint-mobile) {
                font-size: 1.5625rem;
                font-family: $font-family-bold;
                line-height: normal;
            }
            @media (min-width: $breakpoint-tablet) {
                font-size: 1.875rem;
                line-height: normal;
                
            }
        }
        .hr-yellow-1 {
                border: 1px solid var(--yellow);
                margin: 1.5rem auto 1.5rem;
                width: 100%;
                @media(min-width: $breakpoint-mobile) {
                    width: 100%;
                }
                @media(min-width: $breakpoint-tablet) {
                    width: 996px;
                }
            }
        .video-images-section {
            display: flex;
            flex-direction: column;
            padding: 0;
            @media(min-width: $breakpoint-mobile) {
                padding: 0 5%;
            }
            
            > .hr-yellow {
                border: 1px solid var(--yellow);
                margin: 2.5rem 0 1rem;
                @media(min-width: $breakpoint-mobile) {
                    margin: 4.17rem 0 2rem;
                }
                @media(min-width: $breakpoint-tablet) {
                    margin: 5rem 0 2.5rem;
                }
            }
        }
        
    }
}
</style>